import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Producto } from '../entidades/producto';
import { ProductoService } from '../services/producto.service';
import { Categoria } from '../entidades/categoria';
import { CategoriaService } from '../services/categoria.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-form-producto',
  templateUrl: './form.producto.component.html',
})
export class FormProductoComponent implements OnInit {
  public producto: Producto = new Producto()
  public titulo:string = "Crear Producto"
  public errores:string[]
  categorias: Categoria[];

  constructor(private productoService: ProductoService,
    private categoriaService: CategoriaService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private spinner:NgxSpinnerService,
    private _location: Location){ }

  ngOnInit(): void {
    this.cargarProducto();
    this.cargarCategorias();
  }

  cargarCategorias(){
    this.spinner.show();
    this.categoriaService.getCategorias().subscribe(categorias => {
      this.categorias = categorias;
      this.spinner.hide();
    })
  }

  cargarProducto(): void {
    this.activedRoute.params.subscribe(params=> {
      let id = params['id']
      if (id){
        this.spinner.show();
        this.titulo = "Editar Producto";
        this.productoService.getProducto(id).subscribe( (producto) => {
          this.producto = producto;
          this.spinner.hide();
        })
      }
    })
  }

  create(): void{
    this.spinner.show();
    this.productoService.create(this.producto)
    .subscribe(producto => {
      Swal.fire("Éxito","Producto fue agregada a la base de datos con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/producto"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    });
  }

  update(): void{
    this.spinner.show();
    this.productoService.update(this.producto)
    .subscribe(producto => {      
      Swal.fire("Éxito","El producto fue actualizada con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/producto"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    })
  }
  
  goBack(){
    this._location.back();
  }
}
