import { Component, OnInit } from '@angular/core';
import { EmpresaService } from '../services/empresa.service';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { Empresa } from '../entidades/empresa';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
})
export class EmpresaComponent implements OnInit {

  first: boolean = true;
  empresas: Empresa[];
  dtOptions: DataTables.Settings = {};

  constructor(private empresaService: EmpresaService,
    private spinner:NgxSpinnerService,
    public tokenService: TokenStorageService) { }

  ngOnInit() {
    this.spinner.show();
    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresas = empresas;
        this.spinner.hide();
      });
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables
    };
  }
}
