import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Licitacion } from '../entidades/licitacion';
import { Busqueda } from '../entidades/reportes/busqueda';
import { LicitacionService } from '../services/licitacion.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  public chart: any;
  public licitaciones: Licitacion[]
  public busqueda: Busqueda = new Busqueda();
  public dateArray = [];

  constructor(private licitacionService: LicitacionService,
    private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
  }

  createChart(fechas, data) {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: fechas,
        datasets: [
          {
            label: "Licitaciones",
            data: data,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)'
            ],
            borderColor: [
              'rgb(54, 162, 235)'
            ],
            borderWidth: 1
          }
        ],
      }
    });
    this.spinner.hide();
  }

  getDates(startDate, stopDate) {
    this.dateArray = [];
    var inicio = moment(startDate);
    var fin = moment(stopDate);
    while (inicio <= fin) {
      this.dateArray.push(moment(inicio).format('DD-MM-YYYY'))
      inicio = moment(inicio).add(1, 'days');
    }
  }

  mostrarGrafico() {
    this.spinner.show();
    if (this.busqueda.tipo == "XDIA") {
      this.licitacionService.findAllLicitacionByFechas(this.busqueda).subscribe(resp => {
        let fechas = [];
        let data = [];
        resp.forEach(cantidad => {
          fechas.push(moment(cantidad.fecha).format("DD-MM-YYYY"))
          data.push(cantidad.cantidad)
        });
        this.createChart(fechas, data);
      })
    }
  }
}
