<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">Ordenes de compra</h5>
          </div>
          <div class="card-body">
            <div class="my-2 text-left">
              <button *ngIf="tokenService.hasRole('ROLE_USER')" type="button" class="btn btn-rounded btn-primary"
                [routerLink]="['/ordenCompra/form']"><i class="fa fa-plus"></i> Nueva orden de compra</button>
            </div>
            <div *ngIf="ordenes_compra?.length==0" class="alert alert-info">
              No hay registros en la base de datos
            </div>
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
                *ngIf="ordenes_compra?.length>0">
                <thead>
                  <tr>
                    <th>Número factura</th>
                    <th>Número licitación</th>
                    <th>Número orden de compra</th>
                    <th>Nombre empresa licitada</th>
                    <th>Fecha orden de compra</th>
                    <th>Fecha probable de pago</th>
                    <!-- <th>Estado orden</th> -->
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Generar tickets envió</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Seguimiento</th>
                    <th *ngIf="tokenService.hasRole('ROLE_MODERATOR')">Ver detalle gastos</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orden of ordenes_compra">
                    <td *ngIf="orden.numero_factura_entidad!=null">{{orden.numero_factura_entidad}}</td>
                    <td *ngIf="orden.numero_factura_entidad==null">Aun no ingresada</td>
                    <td>{{orden.licitacion.numero_licitacion}}</td>
                    <td>{{orden.numero_orden_compra | uppercase}}</td>
                    <td>{{orden.licitacion.empresa.nombre_empresa}}</td>
                    <td>{{orden.fecha_orden_compra | date:"dd-MM-yyyy":"UTC"}}</td>
                    <td *ngIf="(orden.numero_factura_entidad!=null && !verificarPago(orden)) && sumarDias(orden)[1]>=0"
                      style="background-color: rgb(240, 133, 133);">{{sumarDias(orden)[0]}}</td>
                    <td *ngIf="(orden.numero_factura_entidad!=null && !verificarPago(orden)) && sumarDias(orden)[1]<0"
                      style="background-color: rgb(230, 230, 167);">{{sumarDias(orden)[0]}}</td>
                    <td *ngIf="orden.numero_factura_entidad!=null && verificarPago(orden)"
                      style="background-color:#6ded80;">PAGADA</td>
                    <td *ngIf="orden.numero_factura_entidad==null">Sin factura emitida</td>
                    <!-- <td>{{orden.estado_orden.id}}</td> -->
                    <td *ngIf="tokenService.hasRole('ROLE_USER')">
                      <button type="button" name="editar" class="btn btn-danger" (click)="generarEnvio(envio,orden)"><i
                          class="fas fa-file-pdf"></i> Generar tickets</button>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && orden.seguimiento_compra!=null" >
                      <button type="button" name="editar" class="btn btn-success" (click)="seguimiento(segui,orden)">
                      <i class="fas fa-shipping-fast"></i> Ver</button>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && orden.seguimiento_compra==null">No se ha generado envió</td>
                    <td *ngIf="tokenService.hasRole('ROLE_MODERATOR')">
                      <button type="button" name="editar" class="btn btn-primary" (click)="getDetalle(content,orden)"><i
                          class="fa fa-eye"></i> Ver</button>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER')">
                      <button type="button" name="editar" class="btn btn-primary"
                        [routerLink]="['/ordenCompra/form', orden.id]"><i class="fa fa-edit"></i>
                        Editar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle gastos orden de compra</h4>
  </div>
  <div class="modal-body">
    <br><br>
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseTwo" (click)="getDatosOC()" aria-expanded="false" aria-controls="collapseTwo">
              Detalle de productos (datos desde Mercado Público)
            </button>
          </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            <div class="table-responsive" *ngIf="datosOC">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Producto</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Precio venta</th>
                    <th scope="col">Precio venta Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let detalle of datosOC.Listado[0].Items.Listado">
                    <td>{{detalle.EspecificacionComprador}}</td>
                    <td>{{detalle.Cantidad}}</td>
                    <td>${{detalle.PrecioNeto | number: '2.0-0'}}</td>
                    <td>${{detalle.Total | number: '2.0-0'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="gastos.length>0">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Detalle gastos orden de compra
            </button>
          </h2>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            <div *ngIf="gastos.length>0">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Detalle</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Proveedor</th>
                    <th scope="col">Categoría</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gasto of gastos">
                    <td>{{gasto.descripcion}}</td>
                    <td>${{gasto.valor | number: '2.0-0'}}</td>
                    <td>{{gasto.proveedor}}</td>
                    <td>{{gasto.categoria}}</td>
                    <td>{{gasto.fecha | date:'dd/MM/yyyy':"UTC"}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row container">
      <div class="col-sm-6 alert alert-info" *ngIf="licitacionSeleccionada || datosOC?.Listado.length>0">
        <table class="table table-responsive">
          <thead>
            <tr>
              <th></th>
              <th *ngIf="licitacionSeleccionada">Datos BD nuestra</th>
              <th *ngIf="datosOC?.Listado?.length>0">Datos Mercado Público</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Sub-Total</th>
              <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.sub_total_con_envio
                | number: '2.0-0'}}</td>
              <td *ngIf="datosOC?.Listado?.length>0">${{datosOC.Listado[0].TotalNeto | number:
                '2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">IVA 19%</th>
              <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.iva_total | number:
                '2.0-0'}}</td>
              <td *ngIf="datosOC?.Listado?.length>0">${{datosOC.Listado[0].Impuestos | number:
                '2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Total</th>
              <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.precio_total |
                number: '2.0-0'}}</td>
              <td *ngIf="datosOC?.Listado.length>0">${{datosOC.Listado[0].Total | number:
                '2.0-0'}}</td>
            </tr>
            <br>
            <tr>
              <th scope="row">Precio costo estimado</th>
              <td *ngIf="licitacionSeleccionada.precio_costo!=null">${{licitacionSeleccionada.precio_costo |
                number: '2.0-0'}}</td>
              <td *ngIf="licitacionSeleccionada.precio_costo==null">No se cargo precio costo</td>
            </tr>
            <tr>
              <th scope="row">Precio envió estimado</th>
              <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.envio_total |
                number: '2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Precio costo total estimado</th>
              <td *ngIf="licitacionSeleccionada.precio_costo!=null">
                ${{convertir(licitacionSeleccionada.precio_costo)+convertir(licitacionSeleccionada.envio_total) |
                number: '2.0-0'}}</td>
              <td *ngIf="licitacionSeleccionada.precio_costo==null">No se pudo calcular</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-6 alert alert-warning" *ngIf="licitacionSeleccionada || datosOC?.Listado.length>0">
        <p *ngIf="gastos.length==0">No se han cargado gastos</p>
        <table class="table table-responsive" *ngIf="gastos.length>0">
          <thead>
            <tr>
              <th colspan="2" style="text-align: center;">Resumen gastos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Valor gastos</th>
              <td>${{ordenSeleccionada.valor_costo | number:'2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Valor venta</th>
              <td>${{ordenSeleccionada.valor_venta | number:'2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Ganancia/Perdida</th>
              <td>${{ordenSeleccionada.ganancia_perdida | number:'2.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Margen final</th>
              <td>{{ordenSeleccionada.margen_final}}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i> Volver</button>
  </div>
</ng-template>

<ng-template #envio let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Generar envio</h4>
  </div>
  <div class="modal-body">
    <b>Empresa: </b>{{ordenSeleccionada?.licitacion.empresa.nombre_empresa}}<br><br>
    <div class="alert alert-info" *ngIf="direcciones?.length==0">
      <p>No se han agregado direcciones a la empresa seleccionada</p>
      <button class="btn btn-success" (click)="nuevaDireccion(direccion_nueva)"><i class="fa fa-plus"></i>
        Agregar nueva dirección</button>
    </div>
    <div class="alert alert-info">
      <p>Agregar nueva dirección</p>
      <button class="btn btn-success" (click)="nuevaDireccion(direccion_nueva)"><i class="fa fa-plus"></i>
        Agregar</button>
    </div>
    <form #etiquetaForm="ngForm" (submit)="imprimir(etiquetaForm)">
      <div class="alert alert-info" *ngIf="direcciones?.length>0">
        <p>Seleccione dirección del listado de lo contrario, agregue una nueva</p>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Seleccione dirección</label>
          <div class="col-sm-8">
            <ng-select placeholder="Seleccione dirección de envio" required [(ngModel)]="envio.direccion"
              notFoundText="No se encontro dirección, debe agregarla" name="direccion_envio">
              <ng-option *ngFor="let direccion of direcciones" [value]="direccion">{{direccion.direccion}},
                {{direccion.comuna.comuna}}, {{direccion.comuna.provincia.regione.region}}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Nombre unidad/departamento</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required name="nombre_unidad" [(ngModel)]="envio.unidad">
          </div>
        </div>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Despachar a (lugar)</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required name="despacho" [(ngModel)]="envio.despacho">
          </div>
        </div>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Nombre contacto</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required name="nombre_contacto" [(ngModel)]="envio.nombre_contacto">
          </div>
        </div>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Teléfono contacto:</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required name="telefono_contacto"
              [(ngModel)]="envio.telefono_contacto">
          </div>
        </div>
        <div class="form-group row">
          <label for="apellidos" class="col-form-label col-sm-2">Número bultos</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" required name="numero_bultos" [(ngModel)]="envio.numero_bultos">
          </div>
        </div>
      </div>
      <button class="btn btn-danger" [disabled]="!etiquetaForm.form.valid || !envio.direccion "><i
          class="fas fa-file-pdf"></i>
        Generar etiquetas</button>
        
    </form>
    <button class="btn btn-danger" (click)="cerrarModal()" style="margin-top: 5px;"><i class="fa fa-times"></i> Cerrar</button>

  </div>
</ng-template>

<ng-template #direccion_nueva let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Agregar dirección a empresa</h4>
  </div>
  <div class="modal-body">
    <form #direccionForm="ngForm" (submit)="onSubmit(direccionForm)">
      <div class="form-group row">
        <label for="nombres" class="col-form-label col-sm-2">Dirección completa</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" [(ngModel)]="direccion.direccion" name="direccion" required
            minlength="4" #direccion="ngModel">
          <div class="alert alert-danger" *ngIf="direccion.invalid && (direccion.dirty || direccion.touched)">
            <div *ngIf="direccion.errors.required">
              Dirección completa es obligatorio
            </div>
            <div *ngIf="direccion.errors.minlength">
              Dirección completa debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Seleccione comuna</label>
        <div class="col-sm-8">
          <ng-select placeholder="Seleccione comuna envio" required [(ngModel)]="direccion.comuna"
            notFoundText="No se encontro comuna, debe agregarla" name="comuna">
            <ng-option *ngFor="let comuna of comunas" [value]="comuna">{{comuna.comuna}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <button class="btn btn-success" [disabled]="!direccionForm.form.valid"> <i class="fas fa-save"></i>
        Guardar</button>
    </form>
  </div>
</ng-template>


<ng-template #segui let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Seguimiento</h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Número de seguimiento</th>
            <th scope="col">Ir</th>
            <th scope="col">Dirección</th>
            <th scope="col">Comuna</th>
            <th scope="col">Agregar seguimiento</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let seguimiento of seguimientoSeleccionado">
            <td *ngIf="seguimiento.seguimiento!=null">{{seguimiento.seguimiento}}</td>
            <td *ngIf="seguimiento.seguimiento==null">Sin seguimiento agregado</td>
            <td *ngIf="seguimiento.seguimiento==null"><input class="form-control" [(ngModel)]="seguimiento.numero_seguimiento" name="seguimiento"></td>
            <td *ngIf="seguimiento.seguimiento!=null"> <a href="https://clsclweb.tntchile.cl/txapgw/tracking.asp?boleto={{seguimiento.seguimiento}}" target="_blank">Ir</a></td>
            <td>{{seguimiento.direccion.direccion}}</td>
            <td>{{seguimiento.direccion.comuna.comuna}}</td>
            <td *ngIf="seguimiento.seguimiento==null"><button  class="btn btn-primary" (click)="actualizarSeguimiento(seguimiento)"><i class="fas fa-save"></i> Actualizar</button></td>
            <td *ngIf="seguimiento.seguimiento!=null">Ya agregado</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-times"></i> Cerrar</button>
  </div>
</ng-template>