import { Component, OnInit } from '@angular/core';
import { Usuario } from '../entidades/usuario';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  titulo:string = "Por favor Inicie Sesión";
  usuario:Usuario;
  nombre_sistema:string;

  constructor(private authService: AuthService, 
    private router: Router,
    private spinner:NgxSpinnerService,
    public tokenStorage:TokenStorageService) { 
    this.usuario= new Usuario();
  }

  ngOnInit(): void {
    this.spinner.show();
    if(this.tokenStorage.getToken()){
      Swal.fire("Login", "Ya te encuentras autenticado!", "info");
      this.router.navigate(['/home']);
    }
    this.authService.getNombreSistema().subscribe(resp=>{
      this.nombre_sistema = resp.nombre_sistema;
      this.spinner.hide();
    })
  }

  login():void {
    if(this.usuario.username==null || this.usuario.password==null){
      Swal.fire("Error Login","Usuario o contraseña vacías!","error");
      return;
    }
    this.spinner.show();
    this.authService.login(this.usuario).subscribe(response => {
      console.log(response);
      this.authService.getParametros().subscribe(resp=>{
        this.tokenStorage.saveParametros(resp);
      })
      this.tokenStorage.saveUser(response);
      this.tokenStorage.saveToken(response.accessToken);
      let usuario = this.tokenStorage.getUser();
      console.log(usuario)
      this.router.navigate(["/home"]);
      this.spinner.hide();
      Swal.fire("Login", "Hola "+ usuario.username +" has iniciado sesión con éxito", "success");
    },err => {
      if(err.status == 401 || err.status == 404){
        this.spinner.hide();
        Swal.fire("Error Login", err.error.message,"error");
      }
    }
   );  
  }

}
