import { GastoIngreso } from "./gasto_ingreso";

export class Cuenta {
    nombre_cuenta:string;
    id:number;
    tipo_cuenta:string;
    gasto_ingresos:GastoIngreso[]=[];
    disponible:boolean;
    vigente:boolean;
    total:number=0;
    gastos:number=0;
    ingresos:number=0;
    prestamos:number=0;
    pagos:number=0;
}