import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap, faBars, faBookOpen, faBookReader, faUserLock, faUser, faTable, faHourglass } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})  
export class HeaderComponent {
  title: string = "Distribuidora PMRC SpA.";
  // Se deben declarar todos los iconos que se usaran en el header
  faCity = faCity;
  faBars = faBars;
  faHourglass = faHourglass;
  faUserLock = faUserLock;
  faUser = faUser
  faBuilding= faBuilding;
  faTable = faTable;
  faUniversity= faUniversity;
  faCalendarAlt= faCalendarAlt;
  faHome= faHome;
  faGraduationCap = faGraduationCap;
  faBookOpen = faBookOpen;
  faBookReader = faBookReader;
  enviorement=environment;
  
  
  constructor(public tokenService:TokenStorageService,
    private router: Router,
    private modelService: NgbModal){}
  logout():void{
    Swal.fire("Logout","Has cerrado sesión con éxito","success");
    this.tokenService.signOut();
    this.router.navigate(['/login']);
  }

  verifica():boolean{
    if(this.router.url=="/validarPago"){
      return true;
    }else{
      return false;
    }
  }

  abrirDatos(content){
    this.modelService.open(content);
  }

  cerrarModal(){
    this.modelService.dismissAll();
  }
  
}
