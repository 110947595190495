import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Licitacion } from '../entidades/licitacion';
import { environment } from 'src/environments/environment';
import { Estado } from '../entidades/estado';
import { DetalleLicitacion } from '../entidades/detalle_licitacion';
import { CambioEstado } from '../entidades/cambio_estado';
import { FrecuenciaFedex } from '../entidades/frecuencia_fedex';
import * as moment from 'moment';


@Injectable()
export class LicitacionService {

  private urlEndPoint: string = environment.apiUrl + '/api/licitacion';
  host: string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getLicitacionesPagination(params: any): Observable<any> {
    return this.http.get<any>(this.urlEndPoint, { params });
  }

  getLicitaciones(): Observable<Licitacion[]> {
    return this.http.get<Licitacion[]>(this.urlEndPoint+"/all").pipe(
      catchError(e => {
        return throwError(e);
      }),
      map((response) => {
        let licitaciones = response as Licitacion[];
        return licitaciones.map(licitacion => {
          return licitacion;
        });
      }));
  }

  getUTM(): Observable<any> {
    return this.http.get<any>(this.urlEndPoint+"/utm").pipe(
      catchError(e => {
        return throwError(e);
      }),
    );
  }

  getFrecuenciasFedex(): Observable<FrecuenciaFedex[]> {
    return this.http.get<FrecuenciaFedex[]>(this.urlEndPoint + "/fedex").pipe(
      catchError(e => {
        return throwError(e);
      }),
      map((response) => {
        let frecuencias = response as FrecuenciaFedex[];
        return frecuencias.map(frecuencia => {
          return frecuencia;
        });
      }));
  }

  getEstados(): Observable<Estado[]> {
    return this.http.get<Estado[]>(this.urlEndPoint + '/estados').pipe(
      catchError(e => {
        return throwError(e);
      }),
      map((response) => {
        let estados = response as Estado[];
        return estados.map(estado => {
          return estado;
        });
      }));
  }

  create(licitacion: Licitacion): Observable<any> {
    return this.http.post(this.urlEndPoint, licitacion).pipe(
      map((response: any) => response as any),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  cambiarEstado(estado: CambioEstado): Observable<any> {
    return this.http.post(this.urlEndPoint + "/cambiarEstado", estado).pipe(
      map((response: any) => response as any),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getLicitacion(id): Observable<Licitacion> {
    return this.http.get<Licitacion>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/licitacion']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getLicitacionByNumeroLicitacion(id): Observable<Licitacion> {
    return this.http.get<Licitacion>(`${this.urlEndPoint}/byNumeroLicitacion/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/licitacion']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  findAllLicitacionByFechas(body): Observable<any> {
    return this.http.post<any>(`${this.urlEndPoint}/findAllLicitacionByFechas`,body).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/licitacion']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }


  getDetalleLicitacion(id): Observable<DetalleLicitacion[]> {
    console.log("Licitación ID: "+id);
    return this.http.get<DetalleLicitacion[]>(`${this.urlEndPoint}/detalle/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/licitacion']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(licitacion: Licitacion): Observable<any> {
    return this.http.put<Licitacion>(`${this.urlEndPoint}/${licitacion.id}`, licitacion).pipe(
      map((response: any) => response),
      catchError(e => {
        if (e.status == 400) {
          return throwError(e);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
