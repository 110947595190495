import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { DecimalPipe, registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import localeEs from '@angular/common/locales/es-CL';
import { LoginComponent } from './usuarios/login.component';
import { AuthGuard } from './usuarios/guards/auth.guard';
import { RoleGuard } from './usuarios/guards/role.guard';
import { TokenInterceptor } from './usuarios/interceptors/token.interceptor';
import { AuthInterceptor } from './usuarios/interceptors/auth.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './home/home.component';
import { DataTablesModule } from 'angular-datatables';
import { RolService } from './services/rol.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioService } from './services/usuario.service';
import { FormUsuarioComponent } from './usuarios/form.usuario.component';
import { Ng9RutModule } from 'ng9-rut';
import { FormPasswordComponent } from './usuarios/form.password.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormSistemaComponent } from './sistema/form.sistema.component';
import { SistemaService } from './services/sistema.service';

import { SanitizeHtmlPipe } from './helper/sanitize';
import { EmpresaComponent } from './empresa/empresa.component';
import { PerfilService } from './services/perfil.service';
import { FormEmpresaComponent } from './empresa/form.empresa.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComunaService } from './services/comuna.service';
import { ExcelService } from './services/excel.service';
import { DatePipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EmpresaService } from './services/empresa.service';
import { FormLicitacionComponent } from './licitacion/form.licitacion.component';
import { LicitacionComponent } from './licitacion/licitacion.component';
import { LicitacionService } from './services/licitacion.service';
import { ProductoService } from './services/producto.service';
import { ProductoComponent } from './producto/producto.component';
import { FormProductoComponent } from './producto/form.producto.component';
import { CategoriaService } from './services/categoria.service';
import { MypipePipe } from './helper/pipi.component';
import { FormCategoriaComponent } from './categoria/form.categoria.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { OrdenCompraComponent } from './orden-compra/orden-compra.component';
import { FormOrdenCompraComponent } from './orden-compra/form.orden_compra.component';
import { OrdenCompraService } from './services/orden_compra.service';
import { ReporteComponent } from './reporte/reporte.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ContabilidadComponent } from './contabilidad/contabilidad.component';
import { ContabilidadService } from './services/contabilidad.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { CuentaComponent } from './cuenta/cuenta.component';
import { FormCuentaComponent } from './cuenta/form.cuenta.component';

registerLocaleData(localeEs, 'es-CL');

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'ajustes', component: FormSistemaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'usuarios/cambiarPassword', component: FormPasswordComponent },

  //LISTO
  { path: 'reporte', component: ReporteComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'contabilidad', component: ContabilidadComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_MODERATOR" } },

  //LISTO
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'empresa/form', component: FormEmpresaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'empresa/form/:id', component: FormEmpresaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'categoria', component: CategoriaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'categoria/form', component: FormCategoriaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'categoria/form/:id', component: FormCategoriaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'producto', component: ProductoComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'producto/form', component: FormProductoComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'producto/form/:id', component: FormProductoComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'licitacion', component: LicitacionComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'licitacion/form', component: FormLicitacionComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'licitacion/form/:id', component: FormLicitacionComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'ordenCompra', component: OrdenCompraComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'ordenCompra/form', component: FormOrdenCompraComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },
  { path: 'ordenCompra/form/:id', component: FormOrdenCompraComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_USER" } },

  //LISTO
  { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_ADMIN" } },
  { path: 'usuarios/form', component: FormUsuarioComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_ADMIN" } },
  { path: 'usuarios/form/:id', component: FormUsuarioComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_ADMIN" } },

  //LISTO
  { path: 'cuenta', component: CuentaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_MODERATOR" } },
  { path: 'cuenta/form', component: FormCuentaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_MODERATOR" } },
  { path: 'cuenta/form/:id', component: FormCuentaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: "ROLE_MODERATOR" } },

]

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FormEmpresaComponent,
    EmpresaComponent,
    LoginComponent,
    HomeComponent,
    UsuariosComponent,
    FormUsuarioComponent,
    FormPasswordComponent,
    FormSistemaComponent,
    FormLicitacionComponent,
    LicitacionComponent,
    MypipePipe,
    ProductoComponent,
    FormProductoComponent,
    FormCategoriaComponent,
    CategoriaComponent,
    OrdenCompraComponent,
    FormOrdenCompraComponent,
    ReporteComponent,
    ContabilidadComponent,
    FormCuentaComponent,
    CuentaComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    DataTablesModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    FlexLayoutModule,
    NgSelectModule,
    Ng9RutModule,
    NgxPaginationModule,
    NgbModule
  ],
  providers: [EmpresaService, RolService, UsuarioService, SistemaService,
    PerfilService, LicitacionService, ProductoService, CategoriaService,
    ComunaService, ExcelService, OrdenCompraService, ContabilidadService,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    DatePipe, DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]

})
export class AppModule { }
