import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Empresa } from '../entidades/empresa';
import { environment } from 'src/environments/environment';


@Injectable()
export class EmpresaService {

  private urlEndPoint:string = environment.apiUrl+'/api/empresa';
  host :string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getEmpresas() : Observable<Empresa[]> {
    return this.http.get<Empresa[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let empresas = response as Empresa[];
      return empresas.map(empresa => {
        return empresa;
      });
    })
    );
  }

  create(empresa: Empresa) : Observable<any> {
    return this.http.post(this.urlEndPoint, empresa).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getEmpresa(id): Observable<Empresa> {
    return this.http.get<Empresa>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/empresa']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(libro: Empresa) : Observable<any> {
    return this.http.put<Empresa>(`${this.urlEndPoint}/${libro.id}`, libro).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
