import * as moment from "moment";
import { Producto } from "./producto";

export class DetalleLicitacion {
    id:number;
	
	cantidad:number;
	
	precio_venta:number;
	precio_visualizacion:number;
	precio_costo:number;
	costo_envio:number;

	margen:number;
	link_producto:number;
	fecha_visualizacion:string=moment().format("yyyy-MM-DD");;
	producto_id:number;
	licitacion_id:number;
	producto:Producto;

	//Temporales solo para calculos
	precio_venta_total:number;
	precio_costo_total:number;
	costo_envio_total:number;




}