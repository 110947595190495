<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #cuentaForm="ngForm">
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Nombre cuenta</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="cuenta.nombre_cuenta" name="nombre_cuenta"
                    minlength="4" #nombre_cuenta="ngModel" required>
                  <div class="alert alert-danger"
                    *ngIf="nombre_cuenta.invalid && (nombre_cuenta.dirty || nombre_cuenta.touched)">
                    <div *ngIf="nombre_cuenta.errors.minlength">
                      Nombre cuenta debe contener al menos 4 caracteres
                    </div>
                    <div *ngIf="nombre_cuenta.errors.required">
                      Nombre cuenta es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Tipo cuenta</label>
                <div class="col-sm-6">
                  <ng-select [(ngModel)]="cuenta.tipo_cuenta" name="tipo_cuenta"
                    minlength="4" #tipo_cuenta="ngModel" required>
                    <ng-option value="CRÉDITO">Crédito (contraída con bancos o personas)</ng-option>
                    <ng-option value="DÉBITO">Débito (dinero o efectivo circulante)</ng-option>
                  </ng-select>
                  <div class="alert alert-danger" *ngIf="tipo_cuenta.invalid">
                    <div *ngIf="tipo_cuenta.errors.required">
                      Tipo cuenta es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()' *ngIf="!cuenta.id; else elseBlock"
                    [disabled]="!cuentaForm.form.valid" style="margin-right: 5px;"><i class="fa fa-plus"></i>
                    Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()' [disabled]="!cuentaForm.form.valid"
                      style="margin-right: 5px;"><i class="fas fa-edit"></i> Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()"><i class="fa fa-arrow-left"></i>
                    Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>