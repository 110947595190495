import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private tokenService:TokenStorageService,
    private router: Router ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
      if(this.tokenService.getToken()){
        if(this.isTokenExpirado()){
          this.tokenService.signOut();
          this.router.navigate(["/"]);
          return false;
        }
        return true;
      }
    this.router.navigate(["/"]);
    return false;
  }
  isTokenExpirado():boolean{
    let now = new Date().getTime()/1000;
    if(this.tokenService.getToken()){
      return false;
    }
    return true;
  }
}
