<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #productoForm="ngForm">
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Nombre producto</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="producto.nombre_producto" name="nombre_producto"
                   minlength="4" #nombre_producto="ngModel" required>
                  <div class="alert alert-danger"
                    *ngIf="nombre_producto.invalid && (nombre_producto.dirty || nombre_producto.touched)">
                    <div *ngIf="nombre_producto.errors.minlength">
                      Nombre producto debe contener al menos 4 caracteres
                    </div>
                    <div *ngIf="nombre_producto.errors.required">
                      Nombre producto es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Descripción producto (opcional)</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="producto.descripcion_producto" name="descripcion_producto"
                   minlength="4" #descripcion_producto="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="descripcion_producto.invalid && (descripcion_producto.dirty || descripcion_producto.touched)">
                    <div *ngIf="descripcion_producto.errors.minlength">
                      Descripción producto debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Categoría producto</label>
                <div class="col-sm-6">
                  <ng-select [(ngModel)]="producto.categoria_id" placeholder="Seleccione categoría a la cual pertenece el producto"
                  required name="categoria" #categoria="ngModel">
                    <ng-option *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.nombre_categoria}}</ng-option>
                 </ng-select>
                  <div class="alert alert-danger"
                    *ngIf="categoria.invalid && (categoria.dirty || categoria.touched)">
                    <div *ngIf="categoria.errors.required">
                      Categoría producto es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()' *ngIf="!producto.id; else elseBlock"
                    [disabled]="!productoForm.form.valid" style="margin-right: 5px;">Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()'
                      [disabled]="!productoForm.form.valid" style="margin-right: 5px;">Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()">Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>