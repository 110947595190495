import { DetalleLicitacion } from "./detalle_licitacion";
import { Empresa } from "./empresa";
import { Usuario } from "./usuario";

export class Licitacion {
    id:number;
	numero_licitacion:string;
	fecha_cierre:string;
	fecha_adjudicacion:Date;
	fecha_solicitud:Date;
	precio_total:number;
	iva_total:number;
	envio_total:number;
	sub_total_con_envio:number;
	sub_total_sin_envio:number;
	tiempo_estimado_entrega:number;
	dias_licitacion:string;
	observaciones_licitacion:string;
	fecha_vigencia_licitacion:Date;
	tipo_envio:number;
	
	metodo_pago_id:number;
	empresa_id:number;
	costos_fijos:number;
	estado_licitacion_id:number;
	precio_costo:number;
	usuario_id:number;
	ultimo_usuario_editor:string;
	
	user:Usuario;
	detalle_licitacion:DetalleLicitacion[]=[];
	detalle_licitacions:DetalleLicitacion[]=[];
	empresa:Empresa;
}