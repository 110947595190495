import { Perfil } from './perfil';

export class Usuario {
    id:number;
    username:string;
    alias:string;
    password:string;
    email:string;
    first:boolean;
    
    telefono:string;
    correo_corporativo:string;
    cargo:string;
    nombre_completo:string;

    perfiles:Perfil[];
    perfil:Perfil[];
    roles:string[]=[];
    rol:string;
}