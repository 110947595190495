import { Direccion } from "./direccion";
import { OrdenCompra } from "./orden_compra";

export class Seguimiento {
    id:number;
    seguimiento:string;
    direccion_id:number;
    orden_compra_id:number;
    numero_seguimiento:string;

    direccion:Direccion;
    orden_compra:OrdenCompra;
}