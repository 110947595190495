import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Categoria } from '../entidades/categoria';


@Injectable()
export class CategoriaService {

  private urlEndPoint:string = environment.apiUrl+'/api/categoria';
  host :string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getCategorias() : Observable<Categoria[]> {
    return this.http.get<Categoria[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let categorias = response as Categoria[];
      return categorias.map(categoria => {
        return categoria;
      });
    }));
  }


  getCategoria(id): Observable<Categoria> {
    return this.http.get<Categoria>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/categoria']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  create(categoria: Categoria) : Observable<any> {
    return this.http.post(this.urlEndPoint, categoria).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(categoria: Categoria) : Observable<any> {
    return this.http.put<Categoria>(`${this.urlEndPoint}/${categoria.id}`, categoria).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
