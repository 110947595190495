import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private tokenService:TokenStorageService,
    private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.tokenService.getToken()){
        this.router.navigate(['/login']);
        return false;
      }
      let role = next.data['role'] as [];
      if(typeof(role)=="string"){
        if(this.tokenService.hasRole(role)){
          return true;
        }
      }
      Swal.fire("Acceso denegado", "No tienes acceso a este recurso!", "warning");
      this.router.navigate(['/home']);
    return false;
  }
  
}
