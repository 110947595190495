import { DetalleOrden } from "./detalle_orden";
import { Empresa } from "./empresa";
import { GastoIngreso } from "./gasto_ingreso";
import { Licitacion } from "./licitacion";
import { Usuario } from "./usuario";

export class OrdenCompra {
    id:number;
	numero_orden_compra:string;
	fecha_orden_compra:string;
	fecha_envio_compra:Date;
	fecha_entrega_compra:Date;
	seguimiento_compra:string;
	canal_envio:string;
	fecha_pago_factura:Date;

	numero_factura_entidad:string;
	fecha_factura_entidad:string;


	
	valor_costo:number;
	valor_venta:number;
	margen_final:number;
	ganancia_perdida:number;

	usuario_id:number;
	licitacion_id:number;

	user:Usuario;
	gastos:GastoIngreso[]=[];
	gasto_ingresos:GastoIngreso[]=[];
	detalle_orden:DetalleOrden[]=[];
	empresa:Empresa;
	licitacion:Licitacion;
}