<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">Licitaciones</h5>
          </div>
          <div class="card-body">
            <div class="my-2 text-left">
              <button *ngIf="tokenService.hasRole('ROLE_USER')" type="button" class="btn btn-rounded btn-primary"
                [routerLink]="['/licitacion/form']"><i class="fa fa-plus"></i> Nueva licitación</button>
            </div>
            <div class="my-2 text-right">
              <button *ngIf="tokenService.hasRole('ROLE_USER')" type="button" class="btn btn-rounded btn-success"
                (click)="exportar()"><i class="fa fa-file-excel"></i> Exportar a Excel</button>
            </div>
            <div *ngIf="licitaciones?.length==0" class="alert alert-info">
              No hay registros en la base de datos
            </div>
            <div class="col-md-8">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Buscar por número de licitación"
                  [(ngModel)]="title"
                  (keyup)="searchTitle($event)"
                />
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped"
                *ngIf="licitaciones?.length>0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Número licitación</th>
                    <th>Nombre empresa licitada</th>
                    <th>Fecha solicitud</th>
                    <th>Fecha cierre</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Estado licitación</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Descargar cotización</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Ver detalle</th>
                    <th *ngIf="tokenService.hasRole('ROLE_USER')">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let licitacion of licitaciones">
                    <td>{{licitacion.id}}</td>
                    <td>{{licitacion.numero_licitacion}}</td>
                    <td>{{licitacion.empresa.nombre_empresa}}</td>
                    <td>{{licitacion.fecha_solicitud | date:"dd-MM-yyyy":"UTC"}}</td>
                    <td>{{licitacion.fecha_cierre | date:"dd-MM-yyyy":"UTC"}}</td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER')">{{licitacion.estado.descripcion_estado}}
                      <button *ngIf="licitacion.competencia.length==0" class="btn btn-info"
                        (click)="open(content,licitacion)" style="margin-left: 20px;"><i class="fas fa-edit"></i>
                        Cambiar estado</button>
                      <span *ngIf="licitacion.estado.id==2"> - <span *ngIf="licitacion.competencia[0]?.ganada==true">Ganada por nosotros</span>
                      <span *ngIf="licitacion.competencia[0]?.ganada==false && licitacion.competencia.length>0">Ganada por competencia - PERDIDA {{licitacion.competencia[0].tipo_perdida}} - <br><b>Competencia:</b> {{licitacion.competencia[0].nombre_competencia}} - <br><b>Diferencia de </b>${{licitacion.precio_total-licitacion.competencia[0].monto_ofertado_competencia | number: "2.0-0"}}</span></span>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && licitacion.observaciones_licitacion!='CARGA MASIVA'">
                      <button type="button" name="editar" class="btn btn-danger" 
                        (click)="descargarReporte(licitacion,1)" style="margin-bottom: 1px;"><i class="fas fa-file-pdf"></i> Descargar</button>
                        <button type="button" name="editar" class="btn btn-danger"
                        (click)="descargarReporte(licitacion,2)"><i class="fas fa-file-pdf"></i> Ver</button>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && licitacion.observaciones_licitacion=='CARGA MASIVA'">
                      {{licitacion.observaciones_licitacion}} NO SE REGISTRO DETALLE
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && licitacion.observaciones_licitacion!='CARGA MASIVA'">
                      <button type="button" name="editar" class="btn btn-primary"
                        (click)="getDetalle(licitacion.id,contentDetalle)"><i class="fa fa-eye"></i> Ver</button>
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER') && licitacion.observaciones_licitacion=='CARGA MASIVA'">
                      {{licitacion.observaciones_licitacion}} NO SE REGISTRO DETALLE
                    </td>
                    <td *ngIf="tokenService.hasRole('ROLE_USER')">
                      <button type="button" name="editar" class="btn btn-primary"
                      [routerLink]="['/licitacion/form', licitacion.id]"><i class="fas fa-edit"></i> Editar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="col-md-12">
                <pagination-controls
                  previousLabel="Anterior"
                  nextLabel="Siguiente"
                  [responsive]="true"
                  (pageChange)="handlePageChange($event)"
                ></pagination-controls>
              </div>
              <div class="col-md-6">
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    *ngFor="
                      let tutorial of tutorials | paginate : {
                              itemsPerPage: pageSize,
                              currentPage: page,
                              totalItems: count
                            };
                      let i = index
                    "
                    [class.active]="i == currentIndex"
                    (click)="setActiveTutorial(tutorial, i)"
                  >
                    {{ tutorial.title }}
                  </li>
                </ul>
              </div>
              <div class="mt-3">
                Licitaciones por pagina
                <select (change)="handlePageSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                    {{ size }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<table hidden id="excel" *ngIf="licitaciones?.length>0">
  <thead>
    <tr>
      <th>ID</th>
      <th>Número licitación</th>
      <th>Nombre empresa licitada</th>
      <th>Fecha solicitud</th>
      <th>Fecha cierre</th>
      <th>Estado licitación</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let licitacion of licitaciones">
      <td>{{licitacion.id}}</td>
      <td>{{licitacion.numero_licitacion}}</td>
      <td>{{licitacion.empresa.nombre_empresa}}</td>
      <td>{{licitacion.fecha_solicitud | date:"dd-MM-yyyy":"UTC"}}</td>
      <td>{{licitacion.fecha_cierre | date:"dd-MM-yyyy":"UTC"}}</td>
      <td>{{licitacion.estado.descripcion_estado}}</td>
    </tr>
  </tbody>
</table>
<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambio estado licitación</h4>
  </div>
  <div class="modal-body">
    <form #estadoForm="ngForm" (submit)="onSubmit(estadoForm)">
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Nuevo estado licitación</label>
        <div class="col-sm-6">
          <ng-select [(ngModel)]="estadoSeleccionado" (change)="cambiarStatus($event)" placeholder="Seleccione nuevo estado licitación" name="estado"
            #producto="ngModel">
            <ng-option *ngFor="let estado of estados" [value]="estado">{{estado.descripcion_estado}}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="form-group row" *ngIf="estadoSeleccionado?.id==2">
        <label for="apellidos" class="col-form-label col-sm-2">¿Licitación ganada por nosotros?</label>
        <div class="col-sm-6">
          <ng-select [(ngModel)]="ganada" name="ganada" placeholder="¿Ganamos nosotros?">
            <ng-option [value]="true">Si</ng-option>
            <ng-option [value]="false">No</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="form-group row" *ngIf="ganada==false">
        <label for="apellidos" class="col-form-label col-sm-2">Nombre competencia</label>
        <div class="col-sm-6">
          <input class="form-control" name="competencia" [(ngModel)]="competencia" type="text">
        </div>
      </div>
      <div class="form-group row" *ngIf="ganada==false">
        <label for="apellidos" class="col-form-label col-sm-2">Monto ofertado competencia</label>
        <div class="col-sm-6">
          <input class="form-control" name="monto" [(ngModel)]="monto" type="number">
        </div>
        <p><b>Nuestra oferta: </b>${{licitacionSeleccionada.precio_total  | number:'2.0-0'}}</p>
      </div>
      <div class="alert alert-danger" *ngIf="monto && licitacionSeleccionada">
        Monto competencia: <span *ngIf="monto">${{monto | number:'2.0-0'}}</span><br>
        La diferencia fue de ${{diferenciaLicitacion() | number:'2.0-0'}}
      </div>
      <div class="form-group row" *ngIf="ganada==false">
        <label for="apellidos" class="col-form-label col-sm-2">Motivo perdida licitación</label>
        <div class="col-sm-6">
          <ng-select [(ngModel)]="motivoPerdida" name="motivoPerdida" placeholder="Seleccione motivo perdida">
            <ng-option value="POR PRECIO">POR PRECIO</ng-option>
            <ng-option value="PLAZO DE ENTREGA">PLAZO DE ENTREGA</ng-option>
            <ng-option value="POR REQUERIMIENTO TÉCNICO">POR REQUERIMIENTO TÉCNICO</ng-option>
            <ng-option value="ERROR AL LICITAR">ERROR AL LICITAR</ng-option>
          </ng-select>
        </div>
      </div>
      <button class="btn btn-primary" role="button" type="submit" [disabled]="!estadoForm.form.valid"
        style="margin-right: 5px;"><i class="fas fa-save"></i> Cambiar</button>
      <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i> Volver</button>
    </form>
  </div>
</ng-template>

<ng-template #contentDetalle let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Detalle licitación</h4>
  </div>
  <div class="modal-body">
    <div class="alert alert-info">
      <p><b>Número licitación: </b>{{detallesSeleccionados[0].licitacion.numero_licitacion}}<br>
        <b>Tiempo estimado entrega: </b>{{detallesSeleccionados[0].licitacion.tiempo_estimado_entrega}} días<br>
        <b>Usuario licitador: </b>{{detallesSeleccionados[0].licitacion.user.nombre_completo}}<br>
      </p>
    </div>
    <br><br>
    <div *ngIf="detallesSeleccionados.length>0" class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Producto</th>
            <th scope="col">Link producto</th>
            <th scope="col">Cantidad</th>
            <th scope="col">Margen aplicado</th>
            <th scope="col">Precio costo unitario</th>
            <th scope="col">Precio venta unitario sin envió</th>
            <th scope="col">Precio costo envió (unitario)</th>
            <th scope="col">Precio venta unitario con envió</th>
            <th scope="col">Precio venta total sin iva</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detalle of detallesSeleccionados">
            <td>{{detalle.producto.nombre_producto}}</td>
            <td><a href="{{detalle.link_producto}}" target="_blank">Ir</a></td>
            <td>{{detalle.cantidad}}</td>
            <td>{{detalle.margen}}%</td>
            <td>{{detalle.precio_costo | number: '2.0-0'}}</td>
            <td>{{detalle.precio_venta-detalle.costo_envio | number: '2.0-0'}}</td>
            <td>{{detalle.costo_envio | number: '2.0-0'}}</td>
            <td>{{detalle.precio_venta | number: '2.0-0'}}</td>
            <td>{{detalle.precio_venta*detalle.cantidad | number: '2.0-0'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="alert alert-warning">
        <p><b>Sub-total venta (sin envió): </b>${{detallesSeleccionados[0].licitacion.sub_total_sin_envio | number:
          '2.0-0'}}<br>
        <b>Costo envió: </b>${{detallesSeleccionados[0].licitacion.envio_total | number: '2.0-0'}}<br>
        <b>Sub-total venta (con envió):
          </b>${{detallesSeleccionados[0].licitacion.sub_total_con_envio | number: '2.0-0'}}<br>
        <b>IVA 19%: </b>${{detallesSeleccionados[0].licitacion.iva_total | number: '2.0-0'}}<br>
        <b>Total: </b>${{detallesSeleccionados[0].licitacion.precio_total | number: '2.0-0'}}</p>
        <b>Precio costo estimado: </b>${{detallesSeleccionados[0].licitacion.precio_costo | number: '2.0-0'}}<br>
        <b>Precio costo final estimado: </b>${{number(detallesSeleccionados[0].licitacion.precio_costo)+number(detallesSeleccionados[0].licitacion.envio_total) | number: '2.0-0'}}<br>
        <b>Utilidad estimada: </b>${{number(detallesSeleccionados[0].licitacion.precio_total )-(number(detallesSeleccionados[0].licitacion.precio_costo)+number(detallesSeleccionados[0].licitacion.envio_total)) | number: '2.0-0'}}
      </div> 
    </div>
    <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i> Volver</button>

  </div>
</ng-template>