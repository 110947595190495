import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GastoIngreso } from '../entidades/gasto_ingreso';
import { Cuenta } from '../entidades/cuenta';


@Injectable()
export class ContabilidadService {

  private urlEndPoint:string = environment.apiUrl+'/api/contabilidad';
  host :string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getGastosIngresos() : Observable<GastoIngreso[]> {
    return this.http.get<GastoIngreso[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let gastos_ingresos = response as GastoIngreso[];
      return gastos_ingresos.map(gasto_ingreso => {
        return gasto_ingreso;
      });
    }));
  }

  getCuentas() : Observable<Cuenta[]> {
    return this.http.get<Cuenta[]>(this.urlEndPoint+"/cuentas").pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      console.log(response)
      let cuentas = response as Cuenta[];
      return cuentas.map(cuenta => {
        return cuenta;
      });
    }));
  }

  getGastoIngreso(id): Observable<GastoIngreso> {
    return this.http.get<GastoIngreso>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/gasto_ingreso']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getCuenta(id): Observable<Cuenta> {
    return this.http.get<Cuenta>(`${this.urlEndPoint}/cuenta/${id}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/cuenta']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  create(gasto_ingreso: GastoIngreso) : Observable<any> {
    return this.http.post(this.urlEndPoint, gasto_ingreso).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  createCuenta(cuenta: Cuenta) : Observable<any> {
    return this.http.post(this.urlEndPoint+"/cuenta", cuenta).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(gasto_ingreso: GastoIngreso) : Observable<any> {
    return this.http.put<GastoIngreso>(`${this.urlEndPoint}/${gasto_ingreso.id}`, gasto_ingreso).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  updateCuenta(cuenta: Cuenta) : Observable<any> {
    return this.http.put<Cuenta>(`${this.urlEndPoint}/cuenta/${cuenta.id}`, cuenta).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
