<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #usuarioForm="ngForm">
              <div class="form-group row">
                <label for="nombres" class="col-form-label col-sm-2">RUT</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" formatRut validateRut [(ngModel)]="usuario.username" name="username" required
                    minlength="7" #username="ngModel">
                  <div class="alert alert-danger" *ngIf="username.invalid && (username.dirty || username.touched)">
                    <div *ngIf="username.errors.required">
                      RUT es obligatorio
                    </div>
                    <div *ngIf="username.errors.minlength">
                      RUT debe contener al menos 7 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="nombres" class="col-form-label col-sm-2">Nombre completo</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="usuario.nombre_completo" name="nombre_completo" required
                    minlength="4" #nombre_completo="ngModel">
                  <div class="alert alert-danger" *ngIf="nombre_completo.invalid && (nombre_completo.dirty || nombre_completo.touched)">
                    <div *ngIf="nombre_completo.errors.required">
                      Nombre completo es obligatorio
                    </div>
                    <div *ngIf="nombre_completo.errors.minlength">
                      Nombre completo debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="rut_usuario" class="col-form-label col-sm-2">Email personal</label>
                <div class="col-sm-6">
                  <input type="email" class="form-control" [(ngModel)]="usuario.email" name="email" required
                    minlength="4" #email="ngModel">
                  <div class="alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.errors.required">
                      Email es obligatorio
                    </div>
                    <div *ngIf="email.errors.minlength">
                      Email debe contener al menos 4 caracteres
                    </div>
                    <div *ngIf="email.errors">
                      {{imprimirErrores(email.errors)}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="rut_usuario" class="col-form-label col-sm-2">Email corporativo</label>
                <div class="col-sm-6">
                  <input type="email" class="form-control" [(ngModel)]="usuario.correo_corporativo" name="correo_corporativo" required
                    minlength="4" #correo_corporativo="ngModel">
                  <div class="alert alert-danger" *ngIf="correo_corporativo.invalid && (correo_corporativo.dirty || correo_corporativo.touched)">
                    <div *ngIf="correo_corporativo.errors.required">
                      Email corporativo es obligatorio
                    </div>
                    <div *ngIf="correo_corporativo.errors.minlength">
                      Email corporativo debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="rut_usuario" class="col-form-label col-sm-2">Telefono celular</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="usuario.telefono" name="telefono" required
                    minlength="4" #telefono="ngModel">
                  <div class="alert alert-danger" *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)">
                    <div *ngIf="telefono.errors.required">
                      Telefono celular es obligatorio
                    </div>
                    <div *ngIf="telefono.errors.minlength">
                      Telefono celular debe contener al menos 9 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="rut_usuario" class="col-form-label col-sm-2">Cargo</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="usuario.cargo" name="cargo" required
                    minlength="4" #cargo="ngModel">
                  <div class="alert alert-danger" *ngIf="cargo.invalid && (cargo.dirty || cargo.touched)">
                    <div *ngIf="cargo.errors.required">
                      Cargo es obligatorio
                    </div>
                    <div *ngIf="cargo.errors.minlength">
                      Cargo debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="ciudad" class="col-form-label col-sm-2">Roles</label>
                <div class="col-sm-6">
                  <ng-select [(ngModel)]="usuario.rol" placeholder="Seleccione rol usuario"
                    name="roles" required #roles="ngModel">
                    <ng-option value="admin">Administrador</ng-option>
                    <ng-option value="moderator">Moderador</ng-option>
                    <ng-option value="user">Usuario solo lectura</ng-option>
                  </ng-select>
                  <div class="alert alert-danger" *ngIf="roles.invalid && (roles.dirty || roles.touched)">
                    <div *ngIf="roles.errors.required">
                      Roles es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()' *ngIf="!usuario.id; else elseBlock"
                    [disabled]="!usuarioForm.form.valid">Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()'
                      [disabled]="!usuarioForm.form.valid">Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()">Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

