<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #empresaForm="ngForm">
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">RUT Empresa (opcional)</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="empresa.rut_empresa" name="rut_empresa"
                   minlength="4" #rut_empresa="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="rut_empresa.invalid && (rut_empresa.dirty || rut_empresa.touched)">
                    <div *ngIf="rut_empresa.errors.minlength">
                      Giro empresa debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="nombres" class="col-form-label col-sm-2">Nombre empresa</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="empresa.nombre_empresa" name="nombre_empresa" required
                    minlength="4" #nombre_empresa="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="nombre_empresa.invalid && (nombre_empresa.dirty || nombre_empresa.touched)">
                    <div *ngIf="nombre_empresa.errors.required">
                      Nombre empresa es obligatorio
                    </div>
                    <div *ngIf="nombre_empresa.errors.minlength">
                      NNombre empresa debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Giro empresa (opcional)</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="empresa.giro_empresa" name="giro_empresa"
                   minlength="4" #giro_empresa="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="giro_empresa.invalid && (giro_empresa.dirty || giro_empresa.touched)">
                    <div *ngIf="giro_empresa.errors.minlength">
                      Giro empresa debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()' *ngIf="!empresa.id; else elseBlock"
                    [disabled]="!empresaForm.form.valid" style="margin-right: 5px;">Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()'
                      [disabled]="!empresaForm.form.valid" style="margin-right: 5px;"><i class="fas fa-edit"></i> Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()">Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>