<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h5 class="m-0">Reportes</h5>
                    </div>
                    <div class="card-body">
                        <label>Tipo de reporte a obtener</label>
                        <ng-select placeholder="seleccione tipo de reporte" [(ngModel)]="busqueda.tipo" notFoundText="No se encontro reporte"
                        placeholder="Seleccione reporte a obtener" name="tipo">
                            <ng-option value="XDIA">Licitaciones por día</ng-option>
                        </ng-select>
                        <div *ngIf="busqueda.tipo">
                            <label>Fecha inicio</label>
                            <input class="form-control" type="date" name="fecha_inicio" [(ngModel)]="busqueda.fecha_inicio">
                            <label>Fecha fin</label>
                            <input class="form-control" type="date" name="fecha_fin" [(ngModel)]="busqueda.fecha_fin">
                            <br>
                            <button class="btn btn-success" [disabled]="!busqueda.fecha_inicio || !busqueda.fecha_fin" (click)="mostrarGrafico()"><i class="fa fa-search"></i> Buscar</button>
                            <div class="chart-container">
                                <canvas id="MyChart">{{ chart }}</canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>