import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrdenCompra } from '../entidades/orden_compra';
import { GastoIngreso } from '../entidades/gasto_ingreso';
import { Direccion } from '../entidades/direccion';
import { Comuna } from '../entidades/comuna';
import { Seguimiento } from '../entidades/envio';



@Injectable()
export class OrdenCompraService {

  private urlEndPoint:string = environment.apiUrl+'/api/ordenCompra';
  host :string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getOrdenesCompra() : Observable<OrdenCompra[]> {
    return this.http.get<OrdenCompra[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let ordenes = response as OrdenCompra[];
      return ordenes.map(orden => {
        return orden;
      });
    }));
  }

  getOrdenCompra(id): Observable<OrdenCompra> {
    return this.http.get<OrdenCompra>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/ordenCompra']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getEnviosOrden(id): Observable<Seguimiento[]> {
    return this.http.get<Seguimiento[]>(`${this.urlEndPoint}/getEnviosOrden/${id}`).pipe(
      catchError(e => {
        if (e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/ordenCompra']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  findAllGastos(id_orden) : Observable<GastoIngreso[]> {
    return this.http.get<GastoIngreso[]>(this.urlEndPoint+"/detalleGastos/"+id_orden).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let gastos = response as GastoIngreso[];
      return gastos.map(gasto => {
        return gasto;
      });
    }));
  }

  findAllDirecciones(id_orden) : Observable<Direccion[]> {
    return this.http.get<Direccion[]>(this.urlEndPoint+"/direcciones/"+id_orden).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let direcciones = response as Direccion[];
      return direcciones.map(direccion => {
        return direccion;
      });
    }));
  }

  create(orden_compra: OrdenCompra) : Observable<any> {
    return this.http.post(this.urlEndPoint, orden_compra).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  createEnvio(body: any) : Observable<any> {
    return this.http.post(this.urlEndPoint+"/createEnvio", body).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  createDireccion(direccion: Direccion) : Observable<any> {
    return this.http.post(this.urlEndPoint+"/direccion", direccion).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getDatosOC(codigo): Observable<any> {
    return this.http.get<any>(`${this.urlEndPoint}/codigo/${codigo}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/ordenCompra']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(orden_compra: OrdenCompra) : Observable<any> {
    return this.http.put<OrdenCompra>(`${this.urlEndPoint}/${orden_compra.id}`, orden_compra).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  updateSeguimiento(envio: any) : Observable<any> {
    return this.http.put<any>(`${this.urlEndPoint}/updateSeguimiento/${envio.orden_compra_id}`, envio).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
