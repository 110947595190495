import { Component, OnInit } from '@angular/core';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { TokenStorageService } from '../services/token-storage.service';
import { Producto } from '../entidades/producto';
import { ProductoService } from '../services/producto.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
})
export class ProductoComponent implements OnInit {

  productos: Producto[];
  dtOptions: DataTables.Settings = {};

  constructor(private productoService: ProductoService,
    private spinner: NgxSpinnerService,
    public tokenService: TokenStorageService) { }

  ngOnInit() {
    this.spinner.show();
    this.productoService.getProductos().subscribe(
      productos => {
        this.productos = productos;
        this.spinner.hide();
      });
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables
    };
  }
}
