import { Component, OnInit } from '@angular/core';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { TokenStorageService } from '../services/token-storage.service';
import { LicitacionService } from '../services/licitacion.service';
import { Licitacion } from '../entidades/licitacion';
import { Estado } from '../entidades/estado';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DetalleLicitacion } from '../entidades/detalle_licitacion';
import { CambioEstado } from '../entidades/cambio_estado';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from '../services/excel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-licitacion',
  templateUrl: './licitacion.component.html',
  styleUrls: ['./licitacion.component.css']
})
export class LicitacionComponent implements OnInit {

  first: boolean = true;
  licitaciones: Licitacion[];
  dtOptions: DataTables.Settings = {};
  public estadoSeleccionado: Estado;
  estados: Estado[];
  licitacionSeleccionada: Licitacion;
  detallesSeleccionados: DetalleLicitacion[] = [];
  ganada: boolean = null;
  competencia: string;
  monto: number;
  motivoPerdida: string;


  constructor(private licitacionService: LicitacionService,
    private decimalPipe: DecimalPipe,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private modalService: NgbModal,
    public tokenService: TokenStorageService) { }

  tutorials: Licitacion[] = [];
  currentTutorial: any = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 30, 50];

  ngOnInit() {
    // this.loading = true;
    this.spinner.show();

    this.licitacionService.getEstados().subscribe(estados => {
      this.estados = estados;
      this.getLicitaciones();
    })
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables,
      order: [0, "DESC"]
    };
  }

  searchTitle(event: any): void {
    this.page = 1;
    this.getLicitaciones();
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  getLicitaciones() {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);
    this.licitacionService.getLicitacionesPagination(params).subscribe(
      response => {
        const { licitaciones, totalItems } = response;
        this.licitaciones = licitaciones;
        this.count = totalItems;
        // this.loading = false;
        this.spinner.hide();
      });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getLicitaciones();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getLicitaciones();
  }

  diferenciaLicitacion() {
    return this.monto - this.licitacionSeleccionada.precio_total;
  }

  open(content, licitacion) {
    this.licitacionSeleccionada = licitacion;
    this.modalService.open(content, { size: "lg" });
  }

  getDetalle(id_licitaction, contentDetalle) {
    this.spinner.show();
    this.licitacionService.getDetalleLicitacion(id_licitaction).subscribe(resp => {
      this.detallesSeleccionados = resp;
      this.spinner.hide();
      this.modalService.open(contentDetalle, { size: "xl" });
    })
  }

  descargarReporte(licitacion, tipo: number) {
    this.licitacionSeleccionada = licitacion;
    this.spinner.show();
    this.licitacionService.getDetalleLicitacion(licitacion.id).subscribe(detalles => {
      this.licitacionSeleccionada.detalle_licitacion = detalles;
      this.generarPDF(tipo);
    })
  }

  onSubmit(estadoForm) {
    this.spinner.hide();
    if (this.estadoSeleccionado.id == this.licitacionSeleccionada.estado_licitacion_id) {
      Swal.fire("Info", "El nuevo estado corresponde al mismo anterior, reintente", "info");
    } else {
      let cambio = new CambioEstado();
      cambio.licitacion_id = this.licitacionSeleccionada.id;
      cambio.ganada = this.ganada;
      cambio.estado_licitacion_id = this.estadoSeleccionado.id;
      cambio.monto_ofertado_competencia = this.monto;
      cambio.nombre_competencia = this.competencia;
      cambio.tipo_perdida = this.motivoPerdida;
      this.licitacionService.cambiarEstado(cambio).subscribe(resp => {
        this.getLicitaciones();
        this.modalService.dismissAll();
        this.spinner.hide();
        this.ganada = null;
        Swal.fire("Éxito", "El estado fue cambiado con éxito", "success");
      })
    }
  }

  cambiarStatus(event) {
    if (this.estadoSeleccionado.id != 2) {
      this.ganada = null;
    }
  }

  cerrarModal() {
    this.modalService.dismissAll();
  }

  exportar() {
    let id_elemento = "excel";
    this.excelService.exportToFile("Licitaciones", id_elemento);
  }

 
  generarPDF(tipo) {
    let usuario = this.licitacionSeleccionada.user;
    let datos_empresa = this.tokenService.getParametros();
    const doc = new jsPDF();
    var img = new Image()
    // // var dgp = new Image()
    let colores:any = [];
    if(environment.empresa=="surfic"){
      img.src = 'assets/images/logo_surfic.png'
      colores = [51, 153, 102]
    }else if(environment.empresa=="papelrubi"){
      img.src = 'assets/images/logo_rubi.png'
      colores = [163, 228, 215]
    }

    doc.addImage(img, 'png', 15, 5, 15, 15, undefined, 'FAST');
    doc.setFontSize(12)
    doc.setFont("TIMES NEW ROMAN", "bold");

    doc.text(datos_empresa.nombre_empresa, 105, 10, { align: 'center' });
    doc.setFont("TIMES NEW ROMAN", "bold");
    doc.text("COTIZACIÓN: ", 180, 10, { align: 'right' })
    doc.text(this.licitacionSeleccionada.id.toString(), 200, 10, { align: 'right' })
    let fecha_solicitud = moment(this.licitacionSeleccionada.fecha_solicitud).format('DD/MM/yyyy');
    let fecha_impresion = moment(new Date()).format('DD/MM/yyyy');
    doc.setFontSize(10)
    doc.setFont("TIMES NEW ROMAN", "normal");
    doc.text("Fecha documento: ", 150, 15, { align: 'left' })
    doc.text(fecha_solicitud, 200, 15, { align: 'right' })
    doc.text("Fecha impresión: ", 150, 20, { align: 'left' })
    doc.text(fecha_impresion, 200, 20, { align: 'right' })

    doc.setFont("TIMES NEW ROMAN", "bold");
    doc.text("Señores", 15, 25)
    doc.text("RUT", 15, 30)
    doc.text("Observa.", 15, 40)
    doc.text("F. de Pago.", 130, 40)

    doc.setFont("TIMES NEW ROMAN", "normal");
    doc.text(":  " + this.licitacionSeleccionada.empresa.nombre_empresa, 30, 25)
    doc.text(":  " + this.licitacionSeleccionada.empresa.rut_empresa, 30, 30)
    doc.text(":  " + this.licitacionSeleccionada.numero_licitacion, 30, 40)
    doc.text(":  30 días fecha factura", 150, 40)

    doc.setFont("TIMES NEW ROMAN", "bold");
    doc.setFontSize(10)
    doc.text('PRESENTE:', 15, 50, { align: 'left' });
    doc.setFont("TIMES NEW ROMAN", "normal");
    doc.text('De nuestra consideración:', 15, 55, { align: 'left' });
    doc.text('Por la presente, tenemos el agrado de Cotizar nuestros productos que detallamos a continuación:', 15, 60, { align: 'left' });

    const columns_cdi = [['DESCRIPCIÓN', 'CANTIDAD', "P. NETO U.", "SUB. NETO"]];
    var data = [];
    let subtotal = 0;
    let iva = 0;
    let total = 0;
    this.licitacionSeleccionada.detalle_licitacion.forEach(detalle => {
      var data_d = [detalle.producto.nombre_producto + " - " + detalle.producto.descripcion_producto
        , detalle.cantidad, "$" + this.decimalPipe.transform(detalle.precio_venta, "1.0-2"),
      "$" + this.decimalPipe.transform((detalle.precio_venta * detalle.cantidad), "1.0-2")]
      subtotal = (subtotal + detalle.precio_venta * detalle.cantidad);
      data.push(data_d);
    })
    iva = subtotal * 19 / 100;
    total = iva + subtotal;


    autoTable(doc, {
      bodyStyles: { font: 'TIMES NEW ROMAN', fontSize: 10 },
      margin: { top: 10 },
      head: columns_cdi,
      body: data,
      startY: 65,
      headStyles: { fillColor: colores, textColor: [255, 255, 255], fontSize: 10, font: "TIMES NEW ROMAN" },
      tableLineColor: [0, 0, 0],
    });
    let finalY = (doc as any).lastAutoTable.finalY;

    doc.setFont("Times New Roman", "bold")
    let resumen1 = "Subtotal ";
    let resumen2 = "IVA ";
    let resumen3 = "Total ";

    doc.text(resumen1, 150, finalY + 5, { align: 'justify', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text(resumen2, 150, finalY + 10, { align: 'justify', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text(resumen3, 150, finalY + 15, { align: 'justify', lineHeightFactor: 1.5, maxWidth: 175 });


    doc.text("$" + this.decimalPipe.transform(subtotal, "1.0-0"), 195, finalY + 5, { align: 'right', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text("$" + this.decimalPipe.transform(iva, "1.0-0"), 195, finalY + 10, { align: 'right', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text("$" + this.decimalPipe.transform(total, "1.0-0"), 195, finalY + 15, { align: 'right', lineHeightFactor: 1.5, maxWidth: 175 });

    doc.setFont("Times New Roman", "normal")

    doc.text(usuario.nombre_completo, 170, finalY + 50, { align: 'center', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text("Ventas", 170, finalY + 55, { align: 'center', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text(usuario.cargo, 170, finalY + 60, { align: 'center', lineHeightFactor: 1.5, maxWidth: 175 });
    doc.text(usuario.telefono, 170, finalY + 65, { align: 'center', lineHeightFactor: 1.5, maxWidth: 175 });

    doc.setFont("Times New Roman", "bold")

    let texto_dias;
    if (this.licitacionSeleccionada.tiempo_estimado_entrega <= 5) {
      texto_dias = "3 a 5"
    } else {
      texto_dias = "4 a 7"
    }
    doc.text("Se adjunta cotización. forma de pago: 30 días fecha de factura. Tiempo de entrega " + texto_dias + " días hábiles. Envió gratis. Contacto: " + usuario.telefono + ", Correo: " + usuario.correo_corporativo, 15, finalY + 30, { align: 'justify', lineHeightFactor: 1.5, maxWidth: 175 });

    if (tipo == 1) {
      doc.save(this.licitacionSeleccionada.id.toString() + " - " + this.licitacionSeleccionada.empresa.nombre_empresa + ".pdf");
    } else {
      doc.output('dataurlnewwindow');
    }
    this.spinner.hide();
  }

  aproximar(valor): number {
    return Math.round(valor);
  }

  number(numero) {
    return parseInt(numero);
  }
}