  <body class="hold-transition sidebar-mini" *ngIf="tokenService.getToken() && !tokenService.getUser().first">
    <div class="wrapper">
      <!-- Navbar -->
      <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
          </li>
          <li class="nav-item d-none d-sm-inline-block">
            <a href="" class="nav-link">Inicio</a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" (click)="abrirDatos(content)" data-slide="true"
            role="button">
               {{tokenService.getUser().username}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-widget="control-sidebar" (click)="tokenService.signOut()" data-slide="true" href="#"
              role="button">
              <i class="fas fa-times-circle"></i> Cerrar sesión
            </a>
          </li>
        </ul>
      </nav>
      <!-- Main Sidebar Container -->
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="index3.html" class="brand-link">
          <img *ngIf="enviorement.empresa=='pmrc'" src="assets/images/logo_pmrc.png" alt="PMRC" class="brand-image img-circle elevation-3" style="opacity: .8">
          <img *ngIf="enviorement.empresa=='papelrubi'" src="assets/images/logo_rubi.png" alt="Papel Rubí" class="brand-image img-circle elevation-3" style="opacity: .8">
          <img *ngIf="enviorement.empresa=='surfic'" src="assets/images/logo_surfic.png" alt="Surfic" class="brand-image img-circle elevation-3" style="opacity: .8">
          <span class="brand-text font-weight-light">{{tokenService.getParametros().acronimo_sistema}}</span>
        </a>
        <!-- Sidebar -->
        <div class="sidebar">
          <!-- Sidebar user panel (optional) -->
          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="info">
              <a href="#" class="d-block">{{tokenService.getUser().username}}</a>
            </div>
            <ul class="dropdown-menu animated fadeInRight m-t-xs">
              <li><a *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="dropdown-item" routerLink="/ajustes">Ajustes de
                  Sistema</a></li>
              <li><a class="dropdown-item" routerLink="/usuarios/cambiarPassword">Cambiar Clave</a></li>
              <li><a class="dropdown-item" (click)="logout()">Cerrar Sesión</a></li>
            </ul>
          </div>
          <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['empresa']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-building"></i>
                  <p>
                    Empresas
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['producto']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-box-open"></i>
                  <p>
                    Productos
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['categoria']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-tag"></i>
                  <p>
                    Categorias
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>

              <li *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['licitacion']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-shopping-cart"></i>
                  <p>
                    Licitaciones
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_USER') || tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['ordenCompra']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-cash-register"></i>
                  <p>
                    Ordenes de compra
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_USER')" class="nav-item">
                <a [routerLink]="['reporte']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-chart-line"></i>
                  <p>
                    Reportes
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_MODERATOR')" class="nav-item">
                <a [routerLink]="['cuenta']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-wallet"></i>
                  <p>
                    Cuentas
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_MODERATOR')" class="nav-item">
                <a [routerLink]="['contabilidad']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-money-bill    "></i>
                  <p>
                    Contabilidad
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
              <li *ngIf="tokenService.hasRole('ROLE_ADMIN')" class="nav-item">
                <a [routerLink]="['usuarios']" routerLinkActive="active" class="nav-link">
                  <i class="nav-icon fas fa-users"></i>
                  <p>
                    Usuarios
                  </p>
                  <span class="sr-only">(current)</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>

      <div class="content-wrapper">
        <div class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">{{titulo}}</h1>
              </div>
              <div class="col-sm-6">
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper wrapper-content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <footer class="main-footer">
        <strong>Copyright &copy; 2022 Cristian Ramírez.</strong> Todos los derechos reservados.
      </footer>
    </div>
  </body>
  <change-password *ngIf="tokenService.getToken() && tokenService.getUser().first"></change-password>
  <app-login  *ngIf="!tokenService.getToken() && verifica()==false"></app-login>
  <app-verifica  *ngIf="verifica()"></app-verifica>

  <ng-template #content let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Datos usuario y empresa</h4>
    </div>
    <div class="modal-body">
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                Datos usuario
              </button>
            </h2>
          </div>
  
          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <b>Nombre usuario: </b>{{tokenService.getUser().username}}<br>
              <b>Nombre completo: </b>{{tokenService.getUser().nombre_completo}}<br>
              <b>Email personal: </b>{{tokenService.getUser().email}}<br>
              <b>Email corporativo: </b>{{tokenService.getUser().correo_corporativo}}<br>
              <b>Telefono: </b>{{tokenService.getUser().telefono}}<br>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Datos empresa
              </button>
            </h2>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <b>Nombre: </b>{{tokenService.getParametros().nombre_empresa}}<br>
              <b>Rut: </b>{{tokenService.getParametros().rut_empresa}}<br>
              <b>Número de cuenta: </b>{{tokenService.getParametros().numero_cuenta_empresa}}<br>
              <b>Banco: </b>{{tokenService.getParametros().banco_empresa}}<br>
              <b>Correo notificación: </b>{{tokenService.getParametros().correo_notificacion_empresa}}<br>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Datos representante legal
              </button>
            </h2>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <b>Nombre representante legal: </b>{{tokenService.getParametros().nombre_representante_legal}}<br>
              <b>Rut representante legal: </b>{{tokenService.getParametros().rut_representante_legal}}<br>
              <b>Correo representante legal: </b>{{tokenService.getParametros().correo_representante_legal}}<br>
              <b>Telefono representante legal: </b>{{tokenService.getParametros().telefono_representante_legal}}<br>
            </div>
          </div>
        </div>
      </div>
    <button type="button" class="btn btn-danger" (click)="cerrarModal()">Volver</button>
    </div>
  </ng-template>
