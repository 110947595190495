<body class="hold-transition login-page" *ngIf="!tokenStorage.getToken()">
<div class="login-box">
  <div class="login-logo">
    <a href="../../index2.html"><b>{{nombre_sistema}}</b></a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Ingrese sus datos</p>
      <form method="post">
        <div class="input-group mb-3">
          <input  [(ngModel)]="usuario.username"  name="username" type="username" class="form-control" placeholder="Ingrese su nombre de usuario">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input [(ngModel)]="usuario.password" name="password" type="password" class="form-control" placeholder="Ingrese contraseña">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
        </div>
        <div class="row">
          <div style="display: flex; justify-content: center; width: 100%;">
            <button (click)="login()" type="submit" class="btn btn-primary btn-block" style="width: 15rem;">Iniciar sesión</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</body>
