import { Component, OnInit } from '@angular/core';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cuenta } from '../entidades/cuenta';
import { ContabilidadService } from '../services/contabilidad.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
})
export class CuentaComponent implements OnInit {

  cuentas: Cuenta[];
  cuentaSeleccionada: Cuenta;
  dtOptions: DataTables.Settings = {};

  constructor(private contabilidadService: ContabilidadService,
    private spinner:NgxSpinnerService,
    private modalService:NgbModal,
    public tokenService: TokenStorageService) { }

  ngOnInit() {
    this.spinner.show();
    this.contabilidadService.getCuentas().subscribe(
      cuentas => {
        console.log(cuentas);
        this.cuentas = cuentas;
        this.spinner.hide();
      });
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables
    };
  }

  abrirModal(content,cuenta){
    this.cuentaSeleccionada=cuenta;
    console.log(this.cuentaSeleccionada)
    this.modalService.open(content,{size:'xl'});
  }

  cerrarModal(){
    this.modalService.dismissAll();
  }
}
