import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../services/usuario.service';
import { Location } from '@angular/common';
import { ChangePasswordForm } from '../entidades/change.password';
import { Usuario } from '../entidades/usuario';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../services/token-storage.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'change-password',
  templateUrl: './form.password.component.html',
})
export class FormPasswordComponent implements OnInit {
  public change: ChangePasswordForm = new ChangePasswordForm()
  public titulo:string = "Cambiar contraseña"
  public errores:string[];
  private _usuario: Usuario;
  showPassword: boolean;
  showPasswordNew: boolean;
  showPasswordCon: boolean;

  constructor(public tokenService: TokenStorageService,
    private authService: AuthService,
    private _location: Location){ }
    
  ngOnInit(): void {
    this.showPassword = false;
    this.showPasswordNew = false;
    this.showPasswordCon = false;
  }
  
  showHidePassword(e) {
    this.showPassword = e.target.checked;
  }

  mostrarClavesNuevas(e) {
    this.showPasswordNew = e.target.checked;
    this.showPasswordCon = e.target.checked;
  }

  cambiarPassword(): void{
    if(this.change.newPassword==this.change.confirmPassword){
      this.change.username = this.tokenService.getUser().username;
      this._usuario = this.tokenService.getUser();
      
      this.change.username = this._usuario.username
      this.change.id = 1;
      this.authService.cambiarPassword(this.change)
      .subscribe(change => {
        this.tokenService.signOut();
        Swal.fire("Contraseña Cambiada", `Se ha cambiado la contraseña con éxito, vuelva a iniciar sesión`, 'success')
      },
      err => {
        Swal.fire("Error",err.error.message,"error");
      });
    }else{
      Swal.fire("Error","La contraseña de confirmación y nueva no coinciden, reintente!","error");
    }
  }

  goBack(){
    this._location.back();
  }

}
