<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">Cuentas</h5>
          </div>
          <div class="card-body">
            <div class="my-2 text-left">
              <button *ngIf="tokenService.hasRole('ROLE_MODERATOR')" type="button" class="btn btn-rounded btn-primary"
                [routerLink]="['/cuenta/form']"><i class="fa fa-plus"></i> Crear cuenta</button>
            </div>
            <div *ngIf="cuentas?.length==0" class="alert alert-info">
              No hay registros en la base de datos
            </div>
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
                *ngIf="cuentas?.length>0">
                <thead>
                  <tr>
                    <th>Nombre cuenta</th>
                    <th>Tipo cuenta</th>
                    <th>Ver cuentas</th>
                    <th *ngIf="tokenService.hasRole('ROLE_MODERATOR')">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cuenta of cuentas">
                    <td>{{cuenta.nombre_cuenta}}</td>
                    <td>{{cuenta.tipo_cuenta}}</td>
                    <td><button class="btn btn-primary" (click)="abrirModal(content,cuenta)"><i class="fa fa-eye"></i>
                        Ver</button></td>
                    <td *ngIf="tokenService.hasRole('ROLE_MODERATOR')">
                      <button type="button" name="editar" class="btn btn-primary"
                        [routerLink]="['/cuenta/form', cuenta.id]"><i class="fas fa-edit"></i> Editar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Gastos en la cuenta {{cuentaSeleccionada.nombre_cuenta}}</h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <div class="alert alert-info"*ngIf="cuentaSeleccionada?.gasto_ingresos?.length==0">
        La cuenta seleccionada no registra movimientos.
      </div>
      <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
        *ngIf="cuentaSeleccionada?.gasto_ingresos?.length>0">
        <thead>
          <tr>
            <th hidden>ID</th>
            <th>Fecha</th>
            <th>Descripción</th>
            <th>Categoría</th>
            <th>Valor</th>
            <th>Número documento</th>
            <th>Tipo documento</th>
            <th>Proveedor</th>
            <th>Orden de compra</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let gasto_ingreso of cuentaSeleccionada?.gasto_ingresos">
            <td hidden>{{gasto_ingreso.id}}</td>
            <td>{{gasto_ingreso.fecha | date : "dd/MM/yyyy":"UTC"}}</td>
            <td>{{gasto_ingreso.descripcion}}</td>
            <td>{{gasto_ingreso.categoria}}</td>
            <td [ngStyle]="{'background-color': gasto_ingreso.tipo=='GASTO' ? 'rgb(240, 133, 133)' : '#6ded80' }">
              ${{gasto_ingreso.valor | number:"1.0-0"}}</td>
            <td>{{gasto_ingreso.numero_documento}}</td>
            <td>{{gasto_ingreso.tipo_documento}}</td>
            <td>{{gasto_ingreso.proveedor}}</td>
            <td *ngIf="gasto_ingreso.orden_compra!=null">
              {{gasto_ingreso.orden_compra.numero_orden_compra}}
              - {{gasto_ingreso.orden_compra.licitacion.empresa.nombre_empresa}}</td>
            <td *ngIf="gasto_ingreso.orden_compra==null">NO APLICA</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i>
      Volver</button>

  </div>
</ng-template>