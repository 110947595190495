<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #categoriaForm="ngForm">
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Nombre categoría</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control" [(ngModel)]="categoria.nombre_categoria" name="nombre_categoria"
                   minlength="4" #nombre_categoria="ngModel" required>
                  <div class="alert alert-danger"
                    *ngIf="nombre_categoria.invalid && (nombre_categoria.dirty || nombre_categoria.touched)">
                    <div *ngIf="nombre_categoria.errors.minlength">
                      Nombre categoría debe contener al menos 4 caracteres
                    </div>
                    <div *ngIf="nombre_categoria.errors.required">
                      Nombre categoría es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()' *ngIf="!categoria.id; else elseBlock"
                    [disabled]="!categoriaForm.form.valid" style="margin-right: 5px;">Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()'
                      [disabled]="!categoriaForm.form.valid" style="margin-right: 5px;">Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()">Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>