import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const PARA_KEY = 'auth-para';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
    Swal.fire("Éxito","Sesión cerrada con éxito","success");
  }

  public hasRole(role:string){
    let usuario = this.getUser();
    if(usuario.roles.includes(role)){
      return true;
    }
    return false;
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    console.log(token)
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public saveParametros(parametros: any): void {
    window.sessionStorage.removeItem(PARA_KEY);
    window.sessionStorage.setItem(PARA_KEY, JSON.stringify(parametros));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getParametros(): any {
    const parametros = window.sessionStorage.getItem(PARA_KEY);
    if (parametros) {
      return JSON.parse(parametros);
    }
    return {};
  }
}
