import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cuenta } from '../entidades/cuenta';
import { ContabilidadService } from '../services/contabilidad.service';


@Component({
  selector: 'app-form-cuenta',
  templateUrl: './form.cuenta.component.html',
})
export class FormCuentaComponent implements OnInit {
  public cuenta: Cuenta = new Cuenta()
  public titulo:string = "Crear Cuenta"
  public errores:string[]

  constructor(private cuentaService: ContabilidadService,
    private activedRoute: ActivatedRoute,
    private spinner:NgxSpinnerService,
    private router: Router,
    private _location: Location){ }

  ngOnInit(): void {
    this.cargarCategoria();
  }

  cargarCategoria(): void {
    this.activedRoute.params.subscribe(params=> {
      let id = params['id']
      if (id){
        this.spinner.show();
        this.titulo = "Editar Cuenta";
        this.cuentaService.getCuenta(id).subscribe( (cuenta) => {
          this.cuenta = cuenta;
          this.spinner.hide();
        })
      }
    })
  }

  create(): void{
    this.spinner.show();
    this.cuentaService.createCuenta(this.cuenta)
    .subscribe(cuenta => {
      Swal.fire("Éxito","Cuenta agregada a la base de datos con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/cuenta"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    });
  }

  update(): void{
    this.spinner.show();
    this.cuentaService.updateCuenta(this.cuenta)
    .subscribe(cuenta => {      
      Swal.fire("Éxito","La cuenta fue actualizada con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/cuenta"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    })
  }
  
  goBack(){
    this._location.back();
  }
}
