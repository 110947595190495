import { Comuna } from "./comuna";

export class Direccion {
    id:number;
    direccion:string;
    comuna_id:number;
    empresa_id:number;


    comuna:Comuna;
}