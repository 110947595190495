import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Usuario } from '../entidades/usuario';
import { environment } from 'src/environments/environment';
import { ChangePasswordForm } from '../entidades/change.password';



const AUTH_API = environment.apiUrl+'/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient) { }

  login(usuario:Usuario):Observable<any> {
    let username = usuario.username;
    let password = usuario.password;
    return this.http.post(AUTH_API + 'signin', {
      username,
      password
    }, httpOptions);
  }

    register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username,
      email,
      password
    }, httpOptions);
  }

  getParametros(): Observable<any> {
    return this.http.get<any>(AUTH_API+"getParametros");
  }

  getNombreSistema(): Observable<any> {
    return this.http.get<any>(`${AUTH_API}getNombreSistema`);
  }
  
  cambiarPassword(change: ChangePasswordForm) : Observable<any> {
    return this.http.post(AUTH_API + 'changePassword', {
      change
    }, httpOptions);
  }


   utf8Decode(utf8String: string) {
    if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
    const unicodeString = utf8String.replace(
        /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, 
        function(c) {
            var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
            return String.fromCharCode(cc); }
    ).replace(
        /[\u00c0-\u00df][\u0080-\u00bf]/g,    
        function(c) {
            var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
            return String.fromCharCode(cc); }
    );
    return unicodeString;
  }
}
