import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Pipe({
  name: 'mypipe'
})
export class MypipePipe  extends CurrencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const val = value && value.split('$');
    const trans = val && val[1];
    return trans ? trans.replace(',', '') : value;
  }


}