import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Cuenta } from '../entidades/cuenta';
import { GastoIngreso } from '../entidades/gasto_ingreso';
import { OrdenCompra } from '../entidades/orden_compra';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { ContabilidadService } from '../services/contabilidad.service';
import { ExcelService } from '../services/excel.service';
import { OrdenCompraService } from '../services/orden_compra.service';
import { TokenStorageService } from '../services/token-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contabilidad',
  templateUrl: './contabilidad.component.html',
  styleUrls: ['./contabilidad.component.css']
})
export class ContabilidadComponent implements OnInit {

  public gastos_ingresos: GastoIngreso[] = [];
  public gasto: GastoIngreso = new GastoIngreso();
  public ordenSeleccionada: OrdenCompra;
  public ordenes_compra: OrdenCompra[] = [];
  public gastos: number = 0;
  public ingresos: number = 0;
  public prestamos: number = 0;
  public pagos: number = 0;

  public vendido: number = 0;
  public no_recibido: number = 0;
  public ganancia: number = 0;
  public ganancia_calculada: number = 0;

  public dinero_cuenta: number = 0;
  public total_deudas: number = 0;
  public disponible: number = 0;
  enviorement=environment;

  pregunta_orden: string;
  dtOptions: DataTables.Settings = {};
  //CRISTIAN, MAGGI, PATTY, RICK
  public categorias = [{ id: 1, nombre_categoria: "ADQUISICIONES" }, { id: 2, nombre_categoria: "TRANSPORTE" }, { id: 3, nombre_categoria: "MATERIALES DE EMBALAJE" }
    , { id: 4, nombre_categoria: "SERVICIOS" }, { id: 5, nombre_categoria: "LOGÍSTICOS" }, { id: 6, nombre_categoria: "CAPITAL DE TRABAJO" }
    , { id: 7, nombre_categoria: "INGRESO VENTA" }, { id: 8, nombre_categoria: "NOTA DE CREDITO" }, { id: 9, nombre_categoria: "PAGO TARJETA" }];

  public cuentas: Cuenta[] = [];

  constructor(private contabilidadService: ContabilidadService,
    public tokenService: TokenStorageService,
    private ordenCompraService: OrdenCompraService,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.contabilidadService.getCuentas().subscribe(cuentas => {
      this.cuentas = cuentas;
      this.spinner.hide();
      this.calcularIngresosCapital();
      this.contabilidadService.getGastosIngresos().subscribe(resp => {
        this.gastos_ingresos = resp;
        this.ordenCompraService.getOrdenesCompra().subscribe(ordenes => {
          let costo = 0;
          this.ordenes_compra = ordenes;
          ordenes.forEach(orden => {
            orden.licitacion.detalle_licitacions.forEach(detalle => {
              costo += Number(detalle.precio_costo) * detalle.cantidad;
            })
            costo+= Number(orden.licitacion.envio_total);
            let no_pagado = true;
            orden.gasto_ingresos.forEach(gasto => {
              if(gasto.tipo=="INGRESO"){
                this.ganancia_calculada+=gasto.valor;
              }else{
                this.ganancia_calculada-=gasto.valor;
              }    
              if (gasto.categoria == 'INGRESO VENTA') {
                no_pagado = false;
              }
            })
            this.vendido += Number(orden.licitacion.precio_total);
            if (no_pagado) {
              this.no_recibido += Number(orden.licitacion.precio_total);
            }
          })
          this.ganancia=this.vendido-costo;
        })
        this.spinner.hide();
      })
      this.dtOptions = {
        language: DatatablesEspaniol.spanish_datatables,
        order: [0, "DESC"]
      };
    })
  }

  exportar(){
    let id_elemento = "excel";
    this.excelService.exportToFile("Contabilidad",id_elemento);
  }

  getCuenta(id:number){
    let i = this.cuentas.findIndex(cuenta=>cuenta.id=id);
    return this.cuentas[i].nombre_cuenta;
  }

  calcularIngresosCapital() {
    this.gastos = 0;
    this.ingresos = 0;
    this.prestamos = 0;
    this.vendido = 0;
    this.ganancia = 0;
    this.dinero_cuenta = 0;
    this.ganancia_calculada = 0;
    this.no_recibido = 0;
    this.total_deudas= 0;
    this.cuentas.forEach(cuenta => {
      cuenta.total = 0;
      cuenta.gastos = 0;
      cuenta.ingresos = 0;
      cuenta.prestamos = 0;
      cuenta.pagos = 0;
      cuenta.gasto_ingresos.forEach(gasto => {
        if (gasto.tipo == "INGRESO") {
          cuenta.ingresos += gasto.valor;
          this.ingresos += gasto.valor;
        } else if ((gasto.tipo == "GASTO" || gasto.tipo =="PAGO") && cuenta.tipo_cuenta!='DEUDA') {
          cuenta.gastos += gasto.valor;
          this.gastos += gasto.valor;
        } else if (gasto.tipo == "PRÉSTAMO") {
          cuenta.gastos += gasto.valor;
          this.prestamos += gasto.valor;
        }
        if( gasto.categoria == "PAGO TARJETA"){
          cuenta.pagos += gasto.valor;
        }
      })
      if(cuenta.tipo_cuenta=="DEUDA" || cuenta.tipo_cuenta=="CRÉDITO"){
        this.total_deudas+=cuenta.gastos-cuenta.pagos;
      }
      if(cuenta.tipo_cuenta=='DÉBITO'){
        this.disponible+=cuenta.ingresos-cuenta.gastos
      }
    })
    this.dinero_cuenta = this.ingresos - this.gastos;
  }

  compareWith(item, selected) {
    return item.id === selected.id
  }

  abrirModal(content) {
    this.ordenSeleccionada = null;
    this.modalService.open(content, { size: "xl" });
  }

  actualizarGasto(detalle: GastoIngreso, content) {
    detalle.fecha = moment(detalle.fecha).format("yyyy-MM-DD")
    this.gasto = detalle;
    this.modalService.open(content, { size: "xl" });
  }

  agregarGasto() {
    // this.spinner.show();
    if (this.ordenSeleccionada) {
      this.gasto.orden_compra_id = this.ordenSeleccionada.id;
      this.ordenCompraService.findAllGastos(this.ordenSeleccionada.id).subscribe(resp => {
        this.ordenSeleccionada.gastos = resp;
        this.gasto.orden_compra = this.ordenSeleccionada;
        this.crearGasto();
      })
    } else {
      this.crearGasto();
    }
  }

  crearGasto() {
    // this.gasto.pago=null;
    if(this.gasto.pago!=null){
      this.gasto.pago.gasto_ingresos=null;
    }
    if(this.gasto.cuenta!=null){
      this.gasto.cuenta.gasto_ingresos=null;
    }
    this.contabilidadService.create(this.gasto).subscribe(resp => {
      this.contabilidadService.getGastosIngresos().subscribe(resp2 => {
        this.modalService.dismissAll();
        Swal.fire("Éxito", "Se agrego el " + this.gasto.tipo + " de manera exitosa", "success");
        this.gasto = new GastoIngreso();
        this.ngOnInit();
        this.spinner.hide();
      })
    })
  }

  update() {
    this.contabilidadService.update(this.gasto).subscribe(resp => {
      this.contabilidadService.getGastosIngresos().subscribe(resp2 => {
        this.gastos_ingresos = resp2;
        this.modalService.dismissAll();
        Swal.fire("Éxito", "Se actualizo el " + this.gasto.tipo + " de manera exitosa", "success");
        this.gasto = new GastoIngreso();
        this.calcularIngresosCapital();
        this.spinner.hide();
      })
    })
  }

  calcularTotales() {
    let precio_venta = this.ordenSeleccionada.licitacion.precio_total;
    this.ordenSeleccionada.valor_costo = 0;
    this.ordenSeleccionada.gastos.forEach(gasto => {
      this.ordenSeleccionada.valor_costo += gasto.valor;
    })
    this.ordenSeleccionada.valor_venta = Number(precio_venta);
    this.ordenSeleccionada.margen_final = Math.round((precio_venta - this.ordenSeleccionada.valor_costo) / Number(precio_venta) * 100);
    this.ordenSeleccionada.ganancia_perdida = precio_venta - this.ordenSeleccionada.valor_costo;
  }

  cerrarModal() {
    this.gasto = new GastoIngreso();
    this.modalService.dismissAll();
  }
}