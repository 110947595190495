import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { Categoria } from '../entidades/categoria';
import { CategoriaService } from '../services/categoria.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-form-categoria',
  templateUrl: './form.categoria.component.html',
})
export class FormCategoriaComponent implements OnInit {
  public categoria: Categoria = new Categoria()
  public titulo:string = "Crear Categoría"
  public errores:string[]

  constructor(private categoriaService: CategoriaService,
    private activedRoute: ActivatedRoute,
    private spinner:NgxSpinnerService,
    private router: Router,
    private _location: Location){ }

  ngOnInit(): void {
    this.cargarCategoria();
  }

  cargarCategoria(): void {
    this.activedRoute.params.subscribe(params=> {
      let id = params['id']
      if (id){
        this.spinner.show();
        this.titulo = "Editar Categoría";
        this.categoriaService.getCategoria(id).subscribe( (categoria) => {
          this.categoria = categoria;
          this.spinner.hide();
        })
      }
    })
  }

  create(): void{
    this.spinner.show();
    this.categoriaService.create(this.categoria)
    .subscribe(categoria => {
      Swal.fire("Éxito","Categoría fue agregada a la base de datos con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/categoria"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    });
  }

  update(): void{
    this.spinner.show();
    this.categoriaService.update(this.categoria)
    .subscribe(producto => {      
      Swal.fire("Éxito","La categoría fue actualizada con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/categoria"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    })
  }
  
  goBack(){
    this._location.back();
  }
}
