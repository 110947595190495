import { Cuenta } from "./cuenta";
import { OrdenCompra } from "./orden_compra";

export class GastoIngreso {
    id:number;
    valor:number;
    tipo:string;
    categoria:string;
    numero_documento:string;
    tipo_documento:string;
    sub_categoria:string;
    fecha:string;
    descripcion:string;
    observacion:string;
    orden_compra_id:number;
    proveedor:string;
    
    orden_compra:OrdenCompra;
    cuenta:Cuenta;
    pago:Cuenta;
}