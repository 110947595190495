import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Comuna } from '../entidades/comuna';
import { environment } from 'src/environments/environment';


@Injectable()
export class ComunaService {

  private urlEndPoint:string = environment.apiUrl+'/api/comuna';
  constructor(private http: HttpClient) { }

  getComunas() : Observable<Comuna[]> {
    return this.http.get<Comuna[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let comunas = response as Comuna[];
      return comunas.map(comuna => {
        return comuna;
      });
    })
    );
  }

}
