import { Component, OnInit } from '@angular/core';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { TokenStorageService } from '../services/token-storage.service';
import { Categoria } from '../entidades/categoria';
import { CategoriaService } from '../services/categoria.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
})
export class CategoriaComponent implements OnInit {

  categorias: Categoria[];
  dtOptions: DataTables.Settings = {};

  constructor(private categoriaService: CategoriaService,
    private spinner:NgxSpinnerService,
    public tokenService: TokenStorageService) { }

  ngOnInit() {
    this.spinner.show();
    this.categoriaService.getCategorias().subscribe(
      categorias => {
        this.categorias = categorias;
        this.spinner.hide();
      });
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables
    };
  }
}
