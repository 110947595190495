<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h5 class="m-0">Contabilidad</h5>
                    </div>
                    <div class="card-body">
                        <div class="my-2 text-left">
                            <button *ngIf="tokenService.hasRole('ROLE_MODERATOR')" type="button"
                                class="btn btn-rounded btn-primary" (click)="abrirModal(content)"><i
                                    class="fa fa-plus"></i> Crear nuevo ingreso/gasto</button>
                        </div>
                        <div *ngIf="gastos_ingresos?.length==0" class="alert alert-info">
                            No hay registros en la base de datos
                        </div>
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                            aria-expanded="true" aria-controls="collapseOne">
                                            <b>Resumen general</b>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="alert alert-info">
                                            <p><b>Total ingresos: </b>${{ingresos |number:"1.0-0"}} <br>
                                                <b>Total egresos: </b>${{gastos |number:"1.0-0"}}
                                            </p>
                                            <div class="alert alert-primary">
                                                <b>Vendido: </b>${{vendido |number:"1.0-0"}}<br>
                                                <b>Por recibir: </b>${{no_recibido |number:"1.0-0"}}
                                                <br><br>
                                                <b>Ganancia estimada: </b>${{ganancia |number:"1.0-0"}}<br>
                                                <!-- <b>Ganancia recibida: </b>${{ganancia_calculada |number:"1.0-0"}}<br> -->
                                                <!-- <br>
                                            <b>Dinero por recibir aprox.: </b>${{ganancia-ganancia_calculada |number:"1.0-0"}} -->
                                            </div>
                                            <div class="alert alert-success">
                                                <b>Dinero disponible: </b><br>
                                                <span *ngFor="let cuenta of cuentas">
                                                    <span *ngIf="cuenta.tipo_cuenta!='DEUDA' && cuenta.disponible">
                                                        <b>{{cuenta.nombre_cuenta}} - {{cuenta.tipo_cuenta}}:</b>
                                                        <span *ngIf="cuenta.tipo_cuenta=='DÉBITO'"> ${{cuenta.ingresos -
                                                            cuenta.gastos|number:"1.0-0"}}</span>
                                                        <span *ngIf="cuenta.tipo_cuenta=='CRÉDITO'"> ${{cuenta.ingresos
                                                            -
                                                            cuenta.gastos|number:"1.0-0"}}</span>
                                                        <br>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="alert alert-danger">
                                                <b>Deudas: </b><br>
                                                <span *ngFor="let cuenta of cuentas">
                                                    <span
                                                        *ngIf="cuenta.tipo_cuenta=='DEUDA' || cuenta.tipo_cuenta=='CRÉDITO'">
                                                        <b>{{cuenta.nombre_cuenta}} - {{cuenta.tipo_cuenta}}:
                                                        </b><span *ngIf="cuenta.tipo_cuenta!='DEUDA'">${{(cuenta.gastos
                                                            -cuenta.pagos)
                                                            |number:"1.0-0"}}</span>
                                                        <span *ngIf="cuenta.tipo_cuenta=='DEUDA'">$0</span><br>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="alert alert-warning" role="alert">
                                                <b>Deudas totales:</b> ${{total_deudas | number}}<br>
                                                <span *ngIf="enviorement.empresa=='surfic'"><b>Flujo caja real:</b> ${{no_recibido-total_deudas+900000+disponible | number}}</span>
                                                <span *ngIf="enviorement.empresa!='surfic'"><b>Flujo caja real:</b> ${{no_recibido-total_deudas+disponible | number}}</span>
                                                <br><br><p><i class="fa fa-info"></i> El flujo caja real incorpora todo dinero incorporado en una cuenta vista/corriente (${{disponible | number}}) + el dinero que se encuentra
                                                    en ordenes de compras que aún no se recibe (${{no_recibido | number}}) - las deudas que tiene la empresa (${{total_deudas | number}})
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-success" (click)="exportar()"
                                    style="margin-bottom: 20px; text-align: right;"><i class="fas fa-file-excel"></i>
                                    Exportar</button>
                            </div>
                            <div class="table-responsive">
                                <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
                                    *ngIf="gastos_ingresos?.length>0">
                                    <thead>
                                        <tr>
                                            <th hidden>ID</th>
                                            <th>Fecha</th>
                                            <th>Descripción</th>
                                            <th>Categoría</th>
                                            <th>Valor</th>
                                            <th>Número documento</th>
                                            <th>Tipo documento</th>
                                            <th>Cuenta cargo</th>
                                            <th>Proveedor</th>
                                            <th>Orden de compra</th>
                                            <th *ngIf="tokenService.hasRole('ROLE_MODERATOR')">Editar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let gasto_ingreso of gastos_ingresos">
                                            <td hidden>{{gasto_ingreso.id}}</td>
                                            <td>{{gasto_ingreso.fecha | date : "dd/MM/yyyy":"UTC"}}</td>
                                            <td>{{gasto_ingreso.descripcion}}</td>
                                            <td>{{gasto_ingreso.categoria}}</td>
                                            <td [ngStyle]="{'background-color': gasto_ingreso.tipo=='GASTO' ? 'rgb(240, 133, 133)' : '#6ded80' }">${{gasto_ingreso.valor | number:"1.0-0"}}</td>
                                            <td>{{gasto_ingreso.numero_documento}}</td>
                                            <td>{{gasto_ingreso.tipo_documento}}</td>
                                            <td>{{gasto_ingreso.cuenta.nombre_cuenta}}</td>
                                            <td>{{gasto_ingreso.proveedor}}</td>
                                            <td *ngIf="gasto_ingreso.orden_compra!=null">
                                                {{gasto_ingreso.orden_compra.numero_orden_compra}}
                                                - {{gasto_ingreso.orden_compra.licitacion.empresa.nombre_empresa}}</td>
                                            <td *ngIf="gasto_ingreso.orden_compra==null">NO APLICA</td>
                                            <td *ngIf="tokenService.hasRole('ROLE_MODERATOR')">
                                                <button type="button" name="editar" class="btn btn-primary"
                                                    (click)="actualizarGasto(gasto_ingreso,content)"><i
                                                        class="fas fa-edit"></i> Editar</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #content let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Agregar nuevo costo/gasto a orden de compra</h4>
        </div>
        <div class="modal-body">
            <form #gastoForm="ngForm">
                <div class="form-group row">
                    <label for="apellidos" class="col-form-label col-sm-2">Descripción</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" [(ngModel)]="gasto.descripcion" name="descripcion_gasto"
                            minlength="4" #descripcion_gasto="ngModel" required>
                        <div class="alert alert-danger"
                            *ngIf="descripcion_gasto.invalid && (descripcion_gasto.dirty || descripcion_gasto.touched)">
                            <div *ngIf="descripcion_gasto.errors.minlength">
                                Descripción gasto debe contener al menos 4 caracteres
                            </div>
                            <div *ngIf="descripcion_gasto.errors.required">
                                Descripción gasto es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="apellidos" class="col-form-label col-sm-2">Valor</label>
                    <div class="col-sm-6">
                        <input type="number" class="form-control" [(ngModel)]="gasto.valor" name="valor_gasto"
                            minlength="4" #valor_gasto="ngModel">
                        <div class="alert alert-danger"
                            *ngIf="valor_gasto.invalid && (valor_gasto.dirty || valor_gasto.touched)">
                            <div *ngIf="valor_gasto.errors.required">
                                Valor gasto es obligatorio
                            </div>
                        </div>
                    </div>
                    <span *ngIf="gasto.valor"><b>Valor:</b> ${{gasto.valor | number:"1.0-0"}}</span>
                </div>
                <div class="form-group row">
                    <label for="apellidos" class="col-form-label col-sm-2">Cuenta cargo</label>
                    <div class="col-sm-6">
                        <ng-select [compareWith]="compareWith" [(ngModel)]="gasto.cuenta"
                            placeholder="Seleccione cuenta de cargo" required name="cuenta" #tipo="ngModel">
                            <ng-option *ngFor="let cuenta of cuentas"
                                [value]="cuenta">{{cuenta.nombre_cuenta}}</ng-option>
                        </ng-select>
                        <div class="alert alert-danger" *ngIf="tipo.invalid && (tipo.dirty || tipo.touched)">
                            <div *ngIf="tipo.errors.required">
                                Tipo contabilidad obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="gasto.cuenta">
                    <label for="apellidos" class="col-form-label col-sm-2">Tipo contabilidad</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="gasto.tipo" placeholder="Seleccione tipo de contabilidad" required
                            name="tipo" #tipo="ngModel">
                            <ng-option value="GASTO">GASTO</ng-option>
                            <ng-option value="INGRESO">INGRESO</ng-option>
                            <ng-option value="PAGO"
                            *ngIf="gasto.cuenta.tipo_cuenta=='DÉBITO'">PAGO
                        </ng-option>
                        </ng-select>
                        <div class="alert alert-danger" *ngIf="tipo.invalid && (tipo.dirty || tipo.touched)">
                            <div *ngIf="tipo.errors.required">
                                Tipo contabilidad obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="gasto.cuenta?.tipo_cuenta=='DÉBITO' && gasto.tipo=='PAGO'">
                    <label for="apellidos" class="col-form-label col-sm-2">Tarjeta a pagar</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="gasto.pago" placeholder="Seleccione tarjeta a pagar" required
                            name="pago" #pago="ngModel">
                            <ng-container *ngFor="let cuenta of cuentas">
                                <ng-option *ngIf="cuenta.tipo_cuenta == 'CRÉDITO'" [value]="cuenta">
                                  {{ cuenta.nombre_cuenta }}
                                </ng-option>
                              </ng-container>
                        </ng-select>
                        <div class="alert alert-danger" *ngIf="pago.invalid && (pago.dirty || pago.touched)">
                            <div *ngIf="pago.errors.required">
                                Tarjeta a pagar es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="apellidos" class="col-form-label col-sm-2">Fecha</label>
                    <div class="col-sm-6">
                        <input type="date" class="form-control" [(ngModel)]="gasto.fecha" name="fecha_gasto"
                            minlength="4" #fecha_gasto="ngModel">
                        <div class="alert alert-danger"
                            *ngIf="fecha_gasto.invalid && (fecha_gasto.dirty || fecha_gasto.touched)">
                            <div *ngIf="valor_gasto.errors.required">
                                Fecha gasto es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="(gasto.tipo=='INGRESO' || gasto.tipo=='GASTO')">
                    <label for="apellidos" class="col-form-label col-sm-2">Proveedor</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" [(ngModel)]="gasto.proveedor" name="proveedor_gasto"
                            minlength="4" #proveedor_gasto="ngModel" required>
                        <div class="alert alert-danger"
                            *ngIf="proveedor_gasto.invalid && (proveedor_gasto.dirty || proveedor_gasto.touched)">
                            <div *ngIf="proveedor_gasto.errors.minlength">
                                Proveedor gasto debe contener al menos 4 caracteres
                            </div>
                            <div *ngIf="proveedor_gasto.errors.required">
                                Proveedor gasto es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="gasto.tipo=='INGRESO' || gasto.tipo=='GASTO'">
                    <label for="apellidos" class="col-form-label col-sm-2">Tipo documento recibido</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="gasto.tipo_documento"
                            placeholder="Seleccione tipo de documento recibido" required name="tipo_documento"
                            #tipo_documento="ngModel">
                            <ng-option value="FACTURA">FACTURA</ng-option>
                            <ng-option value="BOLETA">BOLETA</ng-option>
                            <ng-option value="COMPROBANTE">COMPROBANTE</ng-option>
                            <ng-option value="SIN DOCUMENTO">SIN DOCUMENTO</ng-option>
                        </ng-select>
                        <div class="alert alert-danger"
                            *ngIf="tipo_documento.invalid && (tipo_documento.dirty || tipo_documento.touched)">
                            <div *ngIf="tipo_documento.errors.required">
                                Tipo documento recibido es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="gasto.tipo_documento!='SIN DOCUMENTO' && (gasto.tipo=='INGRESO' || gasto.tipo=='GASTO')">
                    <label for="apellidos" class="col-form-label col-sm-2">Número documento recibido</label>
                    <div class="col-sm-6">
                        <input type="text" class="form-control" [(ngModel)]="gasto.numero_documento"
                            name="numero_documento" #numero_documento="ngModel">
                        <div class="alert alert-danger"
                            *ngIf="numero_documento.invalid && (numero_documento.dirty || numero_documento.touched)">
                            <div *ngIf="numero_documento.errors.required">
                                Número documento es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="(gasto.tipo=='INGRESO' || gasto.tipo=='GASTO')">
                    <label for="apellidos" class="col-form-label col-sm-2">Categoría</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="gasto.categoria"
                            placeholder="Seleccione categoría a la cual pertenece el gasto" required
                            name="categoria_gasto" #categoria_gasto="ngModel">
                                <ng-option  *ngFor="let categoria of categorias" [value]="categoria.nombre_categoria">
                                    {{categoria.nombre_categoria}}</ng-option>
                        </ng-select>
                        <div class="alert alert-danger"
                            *ngIf="categoria_gasto.invalid && (categoria_gasto.dirty || categoria_gasto.touched)">
                            <div *ngIf="categoria_gasto.errors.required">
                                Categoría gasto es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="!gasto.id && (gasto.tipo=='INGRESO' || gasto.tipo=='GASTO')">
                    <label for="apellidos" class="col-form-label col-sm-2">Orden de compra</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="pregunta_orden" placeholder="Seleccione tipo de documento recibido"
                            required name="pregunta" #pregunta="ngModel">
                            <ng-option value="SI">SI</ng-option>
                            <ng-option selected value="NO">NO</ng-option>
                        </ng-select>
                        <div class="alert alert-danger"
                            *ngIf="pregunta.invalid && (pregunta.dirty || pregunta.touched)">
                            <div *ngIf="pregunta.errors.required">
                                Orden de compra es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="pregunta_orden=='SI' && !gasto.id">
                    <label for="apellidos" class="col-form-label col-sm-2">Seleccione orden de compra</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="ordenSeleccionada"
                            placeholder="Seleccione orden de compra asignada al gasto/ingreso" required
                            name="orden_compra" #orden_compra="ngModel">
                            <ng-option *ngFor="let orden of ordenes_compra" [value]="orden">
                                {{orden.numero_orden_compra}} - {{orden.licitacion.empresa.nombre_empresa}}</ng-option>
                        </ng-select>
                        <div class="alert alert-danger"
                            *ngIf="orden_compra.invalid && (orden_compra.dirty || orden_compra.touched)">
                            <div *ngIf="orden_compra.errors.required">
                                Orden de compra es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="categoria?.tiene_sub">
                    <label for="apellidos" class="col-form-label col-sm-2">Sub categoria gasto</label>
                    <div class="col-sm-6">
                        <ng-select [(ngModel)]="gasto.categoria"
                            placeholder="Seleccione sub-categoría a la cual pertenece el gasto" required
                            name="sub_categoria_gasto" #sub_categoria_gasto="ngModel">
                            <ng-option *ngFor="let sub_categoria of sub_categorias" [value]="sub_categoria.id">
                                {{sub_categoria.nombre_sub_categoria}}</ng-option>
                        </ng-select>
                        <div class="alert alert-danger"
                            *ngIf="sub_categoria_gasto.invalid && (sub_categoria_gasto.dirty || sub_categoria_gasto.touched)">
                            <div *ngIf="sub_categoria_gasto.errors.required">
                                Sub Categoría gasto es obligatorio
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="apellidos" class="col-form-label col-sm-2">Observaciones (opcional)</label>
                    <div class="col-sm-6">
                        <textarea type="number" class="form-control" [(ngModel)]="gasto.observacion"
                            name="observacion_gasto" #observacion_gasto="ngModel"></textarea>
                    </div>
                </div>
                <button class="btn btn-primary" role="button" type="submit" *ngIf="!gasto.id; else elseBlock"
                    [disabled]="!gastoForm.form.valid" style="margin-right: 5px;" (click)="agregarGasto()"><i
                        class="fas fa-save"></i> Crear</button>
                <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()' [disabled]="!gastoForm.form.valid"
                        style="margin-right: 5px;"><i class="fas fa-edit"></i> Editar</button>
                </ng-template>
                <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i>
                    Volver</button>
            </form>
        </div>
    </ng-template>

    <table hidden class="table table-bordered table-striped" id="excel" *ngIf="gastos_ingresos?.length>0">
        <thead>
            <tr>
                <th>ID</th>
                <th>Fecha</th>
                <th>Descripción</th>
                <th>Categoría</th>
                <th>Valor</th>
                <th>Número documento</th>
                <th>Tipo documento</th>
                <th>Cuenta cargo</th>
                <th>Proveedor</th>
                <th>Orden de compra</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let gasto_ingreso of gastos_ingresos">
                <td>{{gasto_ingreso.id}}</td>
                <td>{{gasto_ingreso.fecha | date : "dd/MM/yyyy":"UTC"}}</td>
                <td>{{gasto_ingreso.descripcion}}</td>
                <td>{{gasto_ingreso.categoria}}</td>
                <td>{{gasto_ingreso.valor}}</td>
                <td>{{gasto_ingreso.numero_documento}}</td>
                <td>{{gasto_ingreso.tipo_documento}}</td>
                <td>{{gasto_ingreso.cuenta.nombre_cuenta}}</td>
                <td>{{gasto_ingreso.proveedor}}</td>
                <td *ngIf="gasto_ingreso.orden_compra!=null">
                    {{gasto_ingreso.orden_compra.numero_orden_compra}}
                    - {{gasto_ingreso.orden_compra.licitacion.empresa.nombre_empresa}}</td>
                <td *ngIf="gasto_ingreso.orden_compra==null">NO APLICA</td>
            </tr>
        </tbody>
    </table>