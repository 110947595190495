import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GastoIngreso } from '../entidades/gasto_ingreso';
import { Licitacion } from '../entidades/licitacion';
import { OrdenCompra } from '../entidades/orden_compra';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { OrdenCompraService } from '../services/orden_compra.service';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Comuna } from '../entidades/comuna';
import { Direccion } from '../entidades/direccion';
import Swal from 'sweetalert2';
import { Envio } from '../entidades/reportes/envio';
import jsPDF from 'jspdf';
import { ComunaService } from '../services/comuna.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Seguimiento } from '../entidades/envio';
import { Location } from '@angular/common';

@Component({
  selector: 'app-orden-compra',
  templateUrl: './orden-compra.component.html'
})
export class OrdenCompraComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  ordenes_compra: OrdenCompra[] = [];
  public ordenSeleccionada: OrdenCompra;

  public licitacionSeleccionada: Licitacion;
  public seguimientoSeleccionado: Seguimiento[];

  public datosOC: any;
  public gastos: GastoIngreso[];
  public comunas: Comuna[] = [];
  private firs: boolean = false;
  private modalDireccion: NgbActiveModal;

  public envio: Envio = new Envio();

  public comunaSeleccionada: Comuna[];
  public direcciones: Direccion[];

  public direccion: Direccion = new Direccion();

  constructor(public tokenService: TokenStorageService,
    public ordenCompraService: OrdenCompraService,
    public comunaService: ComunaService,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.spinner.show();
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables,
      order: [0, "DESC"]
    };
    this.ordenCompraService.getOrdenesCompra().subscribe(resp => {
      this.spinner.hide();
      this.ordenes_compra = resp;
    })
  }

  verificarPago(orden: OrdenCompra) {
    let bandera;
    orden.gasto_ingresos.forEach(gasto => {
      if (gasto.categoria == 'INGRESO VENTA') {
        bandera = true;
      }
    });
    return bandera;
  }

  sumarDias(orden: OrdenCompra) {
    let fechaFactura = orden.fecha_factura_entidad;
    let fecha = moment(fechaFactura, "YYYY-MM-DD").add(30, 'days');
    let fechaActual = moment(new Date());

    let fecha_string = moment(fecha.toDate()).format('DD-MM-YYYY');
    return [fecha_string, fechaActual.diff(fecha, "days")];
  }

  convertir(numero) {
    return Number(numero);
  }

  cerrarModal() {
    this.modalService.dismissAll();
  }

  getDetalle(content, orden: OrdenCompra) {
    this.datosOC = null;
    this.licitacionSeleccionada = null;
    this.gastos = null;
    this.firs = true;
    this.spinner.show();
    this.ordenSeleccionada = orden;
    this.licitacionSeleccionada = orden.licitacion;
    this.ordenCompraService.findAllGastos(orden.id).subscribe(resp => {
      this.modalService.open(content, { size: 'xl' });
      this.spinner.hide();
      this.gastos = resp;
    })
  }

  imprimir(ngForm) {
    this.envio = ngForm.form.value;
    console.log(this.ordenSeleccionada);
    if (this.ordenSeleccionada.seguimiento_compra == null) {
      this.agregarEnvio();
    }
    this.generarEtiquetas();
  }

  agregarEnvio() {
    this.envio.orden = this.ordenSeleccionada;
    if (this.envio.direccion_envio != null) {
      this.ordenCompraService.createEnvio(this.envio).subscribe(resp => {
      });
    }
    window.location.reload();
  }

  getDatosOC() {
    if (this.firs) {
      this.spinner.show();
      this.ordenCompraService.getDatosOC(this.ordenSeleccionada.numero_orden_compra).subscribe(res => {
        this.firs = false;
        this.spinner.hide();
        this.datosOC = res.result;
      })
    }
  }

  goBack() {
    this._location.back();
  }


  generarEnvio(envio, orden) {
    this.spinner.show();
    this.ordenSeleccionada = orden;
    this.comunaService.getComunas().subscribe(resp => {
      this.comunas = resp;
      this.ordenCompraService.findAllDirecciones(this.ordenSeleccionada.licitacion.empresa.id).subscribe(direcciones => {
        this.spinner.hide();
        this.direcciones = direcciones;
      })
    })
    this.modalService.open(envio, { size: "xl" })
  }

  seguimiento(segui, orden: OrdenCompra) {
    this.ordenCompraService.getEnviosOrden(orden.id).subscribe((resp: any) => {
      this.seguimientoSeleccionado = resp;
      this.modalService.open(segui, { size: "xl" })
    })
  }

  actualizarSeguimiento(seguimiento: Seguimiento) {
    if (seguimiento.numero_seguimiento == null) {
      Swal.fire("Info", "Debe agregar número seguimiento", "info");
    } else {
      this.ordenCompraService.updateSeguimiento(seguimiento).subscribe(resp => {
        this.seguimientoSeleccionado = resp;
      })
    }
  }

  agregarNuevo(seguimiento: Seguimiento) {
    console.log(seguimiento);
    this.seguimientoSeleccionado.push(new Seguimiento());
  }

  onSubmit(ngForm) {
    this.direccion = ngForm.form.value;
    this.direccion.comuna_id = this.direccion.comuna.id;
    this.direccion.empresa_id = this.ordenSeleccionada.licitacion.empresa.id;
    this.ordenCompraService.createDireccion(this.direccion).subscribe(resp => {
      Swal.fire("Éxito", "Se ha creado la dirección", "success");
      this.modalDireccion.close();
      this.ordenCompraService.findAllDirecciones(this.ordenSeleccionada.licitacion.empresa.id).subscribe(direcciones => {
        this.direcciones = direcciones;
      })
    })
  }

  generarEtiquetas() {
    const doc = new jsPDF('l', 'mm', [210, 297]);

    for (let i = 1; i <= this.envio.numero_bultos; i++) {
      var img = new Image()
      if (environment.empresa == "pmrc") {
        img.src = 'assets/images/logo_pmrc.png'
      } else if (environment.empresa == "surfic") {
        img.src = 'assets/images/logo_surfic.png'
      } else if (environment.empresa == "papelrubi") {
        img.src = 'assets/images/logo_rubi.png'
      }
      doc.addImage(img, 'png', 15, 15, 15 * 3, 15 * 3, undefined, 'FAST');

      doc.setFontSize(16)
      doc.setFont("Helvetica", "bold");
      doc.text(this.ordenSeleccionada.licitacion.empresa.nombre_empresa, 150, 50, { align: 'center' });
      doc.text(this.envio.nombre_unidad.toUpperCase(), 150, 60, { align: 'center' });

      doc.text("DESPACHO A: " + this.envio.despacho.toUpperCase(), 150, 80, { align: 'center' });

      doc.setFont("Helvetica", "normal")
      doc.text(this.envio.nombre_contacto.toUpperCase(), 150, 100, { align: 'center' });
      doc.text(this.envio.telefono_contacto, 150, 110, { align: 'center' });
      doc.setFont("Helvetica", "bold");
      doc.text(this.envio.direccion_envio.direccion.toUpperCase() + ", " + this.envio.direccion_envio.comuna.comuna.toUpperCase(), 150, 130, { align: 'center' });
      doc.text("BULTO: " + i + " de " + this.envio.numero_bultos, 150, 140, { align: "center" })
      if (this.envio.numero_bultos != i) {
        doc.addPage();
      }
    }

    doc.output('dataurlnewwindow');
    this.spinner.hide();
    // doc.save(this.licitacionSeleccionada.id.toString() + " - " + this.licitacionSeleccionada.empresa.nombre_empresa + ".pdf");
  }

  nuevaDireccion(direccion_nueva) {
    this.direccion = new Direccion();
    this.modalDireccion = this.modalService.open(direccion_nueva, { size: "l" })
  }

}