import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EmpresaService } from '../services/empresa.service';
import { Empresa } from '../entidades/empresa';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-form-empresa',
  templateUrl: './form.empresa.component.html',
})
export class FormEmpresaComponent implements OnInit {
  public empresa: Empresa = new Empresa();
  public titulo:string = "Crear Empresa";
  public errores:string[];

  constructor(private empresaService: EmpresaService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private spinner:NgxSpinnerService,
    private _location: Location){ }

  ngOnInit(): void {
    this.cargarEmpresa()
  }

  cargarEmpresa(): void {
    this.activedRoute.params.subscribe(params=> {
      let id = params['id']
      if (id){
        this.spinner.show();
        this.titulo = "Editar Empresa";
        this.empresaService.getEmpresa(id).subscribe( (empresa) => {
          this.empresa = empresa;
          this.spinner.hide();
        })
      }
    })
  }

  create(): void{
    this.spinner.show();
    this.empresaService.create(this.empresa)
    .subscribe(empresa => {
      Swal.fire("Éxito","Empresa fue agregada a la base de datos con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/empresa"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    });
  }

  update(): void{
    this.spinner.show();
    this.empresaService.update(this.empresa)
    .subscribe(empresa => {      
      Swal.fire("Éxito","La Empresa fue actualizada con éxito","success");
      this.spinner.hide();
      this.router.navigate(["/empresa"])
    },
    err => {
      this.errores = err.error.errors as string[];
      this.spinner.hide();
    })
  }
  
  goBack(){
    this._location.back();
  }
}
