<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">{{titulo}}</h5>
          </div>
          <div class="card-body">
            <form #licitacionForm="ngForm">
              <div class="form-group row">
                <label for="nombres" class="col-form-label col-sm-2">Número licitación</label>
                <div class="col-sm-3">
                  <input type="text" class="form-control" [(ngModel)]="licitacion.numero_licitacion"
                    name="numero_licitacion" required minlength="4" #numero_licitacion="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="numero_licitacion.invalid && (numero_licitacion.dirty || numero_licitacion.touched)">
                    <div *ngIf="numero_licitacion.errors.required">
                      Número licitación es obligatorio
                    </div>
                    <div *ngIf="numero_licitacion.errors.minlength">
                      Número licitación debe contener al menos 4 caracteres
                    </div>
                  </div>
                </div>
                <label for="apellidos" class="col-form-label col-sm-1">Seleccione comuna</label>
                <div class="col-sm-4">
                  <ng-select placeholder="Seleccione comuna envió" [(ngModel)]="frecuenciaSeleccionada"
                    notFoundText="No se encontró empresa, debe agregarla" name="comuna">
                    <ng-option *ngFor="let frecuencia of frecuencias" [value]="frecuencia">{{frecuencia.destino}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="form-group row" *ngIf="frecuenciaSeleccionada">
                <label for="apellidos" class="col-form-label col-sm-2">Datos Fedex</label>
                <div class="col-sm-6">
                  <div class="alert alert-info" >
                    <div class="table-responsive">
                      <table class="table table-bordered" >
                        <thead>
                          <tr>
                            <th scope="col">Tiempo transito</th>
                            <th scope="col">Horario entrega</th>
                            <th scope="col">Tipo ciudad</th>
                            <th scope="col">Días entrega</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{frecuenciaSeleccionada.tiempo_transito}}</td>
                            <td>{{frecuenciaSeleccionada.horario_entrega}}</td>
                            <td>{{frecuenciaSeleccionada.tipo_cobro}}</td>
                            <td>{{obtenerFrecuencias(frecuenciaSeleccionada)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Fecha cierre</label>
                <div class="col-sm-3">
                  <input type="date" class="form-control" [(ngModel)]="licitacion.fecha_cierre" name="fecha_cierre"
                    required #fecha_cierre="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="fecha_cierre.invalid && (fecha_cierre.dirty || fecha_cierre.touched)">
                    <div *ngIf="fecha_cierre.errors.required">
                      Fecha cierre es obligatorio
                    </div>
                  </div>
                </div>
                <label for="apellidos" class="col-form-label col-sm-1">Tiempo estimado de entrega (días)</label>
                <div class="col-sm-2">
                  <input type="number" min="0" class="form-control" [(ngModel)]="licitacion.tiempo_estimado_entrega"
                    name="tiempo_estimado_entrega" required #tiempo_estimado_entrega="ngModel">
                  <div class="alert alert-danger"
                    *ngIf="tiempo_estimado_entrega.invalid && (tiempo_estimado_entrega.dirty || tiempo_estimado_entrega.touched)">
                    <div *ngIf="tiempo_estimado_entrega.errors.required">
                      Tiempo estimado de entrega es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Empresa (cliente licitado)</label>
                <div class="col-sm-6">
                  <ng-select [(ngModel)]="licitacion.empresa_id"
                    placeholder="Seleccione cliente al que se le realizara licitación"
                    notFoundText="No se encontró empresa, debe agregarla" required name="empresa" #empresa="ngModel">
                    <ng-option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.nombre_empresa}}
                    </ng-option>
                  </ng-select>
                  <p class="alert alert-warning">Si el cliente no se encuentra en el listado, crearlo haciendo clic <a
                      role="button" (click)="open(content)"><b>aquí</b></a></p>
                  <div class="alert alert-danger" *ngIf="empresa.invalid && (empresa.dirty || empresa.touched)">
                    <div *ngIf="empresa.errors.required">
                      Empresa es obligatorio
                    </div>
                  </div>
                </div>
              </div>
              <div class="alert alert-secondary">
                <div class="form-group row">
                  <label for="apellidos" class="col-form-label col-sm-2">Tipo de calculo de envió</label>
                  <div class="col-sm-4">
                    <ng-select name="tipo_calculo" [compareWith]="compareWith"
                      placeholder="Seleccione método de calculo de costos de envió"
                      notFoundText="No se encontró método" [(ngModel)]="tipo_calculo">
                      <ng-option *ngFor="let tipo of tipos_envio" [value]="tipo.id">{{tipo.descripcion}}</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group row" *ngIf="tipo_calculo==1">
                  <label for="apellidos" class="col-form-label col-sm-2">Costos fijos (envió y distribución)</label>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" [(ngModel)]="licitacion.costos_fijos" name="costos_fijos"
                      #precio_venta="ngModel">
                      <span *ngIf="licitacion.costos_fijos">${{licitacion.costos_fijos|number}}</span>
                  </div>
                  <button class="btn btn-success" (click)="calcularTotales()"
                    *ngIf="detalle_licitacion.length>0"><i class="fas fa-calculator-alt"></i> Re-calcular valores</button>
                </div>
              </div>
              <div class="alert alert-info">
                <br>
                <div class="form-group row">
                  <label for="apellidos" class="col-form-label col-sm-2">Producto a licitar</label>
                  <div class="col-sm-8">
                    <ng-select [(ngModel)]="detalle.producto" notFoundText="No se encontró producto, debe agregarlo"
                      placeholder="Seleccione producto a licitar" name="producto" #producto="ngModel">
                      <ng-option *ngFor="let producto of productos" [value]="producto">{{producto.nombre_producto}} - {{producto.descripcion_producto}} -
                        {{producto.categorium.nombre_categoria}}</ng-option>
                    </ng-select>
                    <p class="alert alert-warning">Si el producto no se encuentra en el listado, crearlo haciendo clic
                      <a role="button" (click)="openP(contentP)"><b>aquí</b></a>
                    </p>
                  </div>
                  <!-- <label for="apellidos" class="col-form-label col-sm-1">Fecha visualización</label>
                  <div class="col-sm-2">
                    <input type="date" class="form-control" readonly [(ngModel)]="detalle.fecha_visualizacion"
                      name="fecha_visualizacion" #fecha_visualizacion="ngModel">
                  </div> -->
                </div>
                <div class="form-group row">
                  <label for="apellidos" class="col-form-label col-sm-2">Link producto</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" [(ngModel)]="detalle.link_producto" name="link_producto"
                      #link_producto="ngModel">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="apellidos" class="col-form-label col-sm-2">Precio costo</label>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" [(ngModel)]="detalle.precio_costo" name="precio_costo"
                      #precio_costo="ngModel">
                      <span *ngIf="detalle.precio_costo">${{detalle.precio_costo|number}}</span>
                  </div>
                  <label for="apellidos" class="col-form-label col-sm-1">Margen</label>
                  <div class="col-sm-1">
                    <input type="number" min="0" class="form-control" [(ngModel)]="detalle.margen" name="margen"
                      #margen="ngModel">
                  </div>
                  <label for="apellidos" class="col-form-label col-sm-1">Precio venta unitario</label>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" [(ngModel)]="detalle.precio_venta" name="precio_venta"
                      [value]="(aproximar(detalle.precio_costo*detalle.margen/100)+detalle.precio_costo) | number:'1.0-0':'es-CL' " #precio_venta="ngModel"
                      readonly>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="apellidos" class="col-form-label col-sm-2">Cantidad</label>
                  <div class="col-sm-1">
                    <input type="number" class="form-control" [(ngModel)]="detalle.cantidad" name="cantidad"
                      #cantidad="ngModel">
                  </div>
                  <label for="apellidos" class="col-form-label col-sm-1">Precio costo total</label>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" [(ngModel)]="detalle.precio_costo_total"
                      name="precio_costo_total" readonly [value]="(detalle.precio_costo*detalle.cantidad) | number:'1.0-0':'es-CL' ">
                  </div>
                  <label *ngIf="tipo_calculo==2" for="apellidos" class="col-form-label col-sm-1">Precio venta sin
                    envio</label>
                  <label *ngIf="tipo_calculo==1 || tipo_calculo==null" for="apellidos"
                    class="col-form-label col-sm-1">Precio venta total</label>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" [(ngModel)]="detalle.precio_venta_total"
                      name="precio_venta_total" readonly
                      [value]="((aproximar(detalle.precio_costo*detalle.margen/100)+detalle.precio_costo)*detalle.cantidad) | number:'1.0-0':'es-CL' ">
                  </div>
                </div>
                <div class="form-group row" *ngIf="tipo_calculo==2">
                  <label for="apellidos" class="col-form-label col-sm-2">Costo envió por producto</label>
                  <div class="col-sm-2">
                    <input type="number" class="form-control" [(ngModel)]="detalle.costo_envio" name="costo_envio">
                  </div>
                  <label for="apellidos" class="col-form-label col-sm-1">Precio venta final (incluye envió)</label>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" name="precio_venta_total" readonly
                      [value]="((aproximar(detalle.precio_costo*detalle.margen/100)+detalle.precio_costo)*detalle.cantidad)+(detalle.costo_envio*detalle.cantidad) | number:'1.0-0':'es-CL'">
                  </div>
                </div>
                <button class="btn btn-success" (click)="agregar()"><i class="fa fa-plus"></i> Agregar a
                  licitación</button>
                <br>
                <br>
              </div>
              <div *ngIf="detalle_licitacion.length>0" class="table-responsive">
                <div class="alert alert-warning">
                  <b><i class="fa fa-exclamation-circle"></i> Al cambiar cualquier valor desde la tabla, debe presionar enter para aplicar los cambios</b>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Producto</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Margen</th>
                      <th scope="col">Precio costo unitario</th>
                      <th scope="col">Precio costo envio (unitario)</th>
                      <th scope="col">Precio venta unitario sin envio</th>
                      <th scope="col">Precio venta unitario con envio</th>
                      <th scope="col">Precio venta total sin iva</th>
                      <th scope="col">Quitar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let detalle of detalle_licitacion; index as i">
                      <td>{{detalle.producto.nombre_producto}}</td>
                      <td><input class="form-control input-sm" type="number" [(ngModel)]="detalle.cantidad" name="detalle_cantidad[{{i}}]"></td>
                      <td><input class="form-control input-sm" type="number" [(ngModel)]="detalle.margen" name="detalle_margen[{{i}}]"></td>
                      <td><input class="form-control input-sm" type="number" [(ngModel)]="detalle.precio_costo" name="detalle_precio_costo[{{i}}]"></td>
                      <td>{{detalle.costo_envio | number: '2.0-0'}}</td>
                      <td>{{detalle.precio_venta-detalle.costo_envio | number: '2.0-0'}}</td>
                      <td>{{detalle.precio_venta | number: '2.0-0'}}</td>
                      <td>{{detalle.precio_venta_total | number: '2.0-0'}}</td>
                      <td><button class="btn btn-danger" (click)="quitar(detalle)"><i class="fa fa-trash"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="alert alert-info">
                <p><b>Sub-total venta (sin envio): </b>${{subtotal | number: '2.0-0'}}<br>
                  <b>Costo envio: </b>${{costo_envio | number: '2.0-0'}}<br>
                  <b>Sub-total venta (con envio): </b>${{subtotal+costo_envio | number: '2.0-0'}}<br>
                  <b>IVA 19%: </b>${{iva | number: '2.0-0'}}<br>
                  <b>Total: </b>${{total | number: '2.0-0'}}</p>
                </div>
                <div class="alert alert-warning" *ngIf="tope_utm!=null">
                  Recuerde que el tope máximo para licitar es de ${{tope_utm | number: '2.0-0'}}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <button class="btn btn-primary" role="button" (click)='create()'
                    *ngIf="!licitacion.id; else elseBlock" [disabled]="!licitacionForm.form.valid"
                    style="margin-right: 5px;"><i class="fas fa-save"></i> Crear</button>
                  <ng-template #elseBlock>
                    <button class="btn btn-primary" role="button" (click)='update()'
                      [disabled]="!licitacionForm.form.valid" style="margin-right: 5px;"><i class="fas fa-edit"></i> Editar</button>
                  </ng-template>
                  <button class="btn btn-danger" mat-raised-button (click)="goBack()"><i class="fa fa-arrow-left"></i> Volver</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Creación nueva empresa</h4>
  </div>
  <div class="modal-body">
    <form #empresaForm="ngForm" (submit)="onSubmit(empresaForm)">
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">RUT Empresa (opcional)</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="empresa.rut_empresa" name="rut_empresa" minlength="4"
            #rut_empresa="ngModel">
          <div class="alert alert-danger" *ngIf="rut_empresa.invalid && (rut_empresa.dirty || rut_empresa.touched)">
            <div *ngIf="rut_empresa.errors.minlength">
              Giro empresa debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="nombres" class="col-form-label col-sm-2">Nombre empresa</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="empresa.nombre_empresa" name="nombre_empresa" required
            minlength="4" #nombre_empresa="ngModel">
          <div class="alert alert-danger"
            *ngIf="nombre_empresa.invalid && (nombre_empresa.dirty || nombre_empresa.touched)">
            <div *ngIf="nombre_empresa.errors.required">
              Nombre empresa es obligatorio
            </div>
            <div *ngIf="nombre_empresa.errors.minlength">
              Nombre empresa debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Giro empresa (opcional)</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="empresa.giro_empresa" name="giro_empresa" minlength="4"
            #giro_empresa="ngModel">
          <div class="alert alert-danger" *ngIf="giro_empresa.invalid && (giro_empresa.dirty || giro_empresa.touched)">
            <div *ngIf="giro_empresa.errors.minlength">
              Giro empresa debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary" role="button" type="submit" *ngIf="!empresa.id; else elseBlock"
        [disabled]="!empresaForm.form.valid" style="margin-right: 5px;"><i class="fas fa-save"></i> Crear</button>
      <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i> Volver</button>
    </form>
  </div>
</ng-template>


<ng-template #contentP let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Creación nuevo producto</h4>
  </div>
  <div class="modal-body">
    <form #productoForm="ngForm" (submit)="onSubmitP(productoForm)">
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Nombre producto</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="producto.nombre_producto" name="nombre_producto"
            minlength="4" #nombre_producto="ngModel" required>
          <div class="alert alert-danger"
            *ngIf="nombre_producto.invalid && (nombre_producto.dirty || nombre_producto.touched)">
            <div *ngIf="nombre_producto.errors.minlength">
              Nombre producto debe contener al menos 4 caracteres
            </div>
            <div *ngIf="nombre_producto.errors.required">
              Nombre producto es obligatorio
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Descripción producto (opcional)</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" [(ngModel)]="producto.descripcion_producto"
            name="descripcion_producto" minlength="4" #descripcion_producto="ngModel">
          <div class="alert alert-danger"
            *ngIf="descripcion_producto.invalid && (descripcion_producto.dirty || descripcion_producto.touched)">
            <div *ngIf="descripcion_producto.errors.minlength">
              Descripción producto debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Categoría producto</label>
        <div class="col-sm-6">
          <ng-select [(ngModel)]="producto.categoria_id"
            placeholder="Seleccione categoría a la cual pertenece el producto" required name="categoria"
            #categoria="ngModel">
            <ng-option *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.nombre_categoria}}
            </ng-option>
          </ng-select>
          <div class="alert alert-danger" *ngIf="categoria.invalid && (categoria.dirty || categoria.touched)">
            <div *ngIf="categoria.errors.required">
              Categoría producto es obligatorio
            </div>
          </div>
        </div>
      </div>
      <button class="btn btn-primary" role="button" type="submit" *ngIf="!producto.id; else elseBlock"
        [disabled]="!productoForm.form.valid" style="margin-right: 5px;"><i class="fas fa-save    "></i> Crear</button>
      <button type="button" class="btn btn-danger" (click)="cerrarModal()"><i class="fa fa-arrow-left"></i> Volver</button>
    </form>
  </div>
</ng-template>