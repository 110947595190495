import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EmpresaService } from '../services/empresa.service';
import Swal from 'sweetalert2';
import { Licitacion } from '../entidades/licitacion';
import { LicitacionService } from '../services/licitacion.service';
import { Empresa } from '../entidades/empresa';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';
import { ProductoService } from '../services/producto.service';
import { Producto } from '../entidades/producto';
import { CategoriaService } from '../services/categoria.service';
import { Categoria } from '../entidades/categoria';
import { DetalleLicitacion } from '../entidades/detalle_licitacion';
import { TokenStorageService } from '../services/token-storage.service';
import { FrecuenciaFedex } from '../entidades/frecuencia_fedex';
import { DecimalPipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-form-licitacion',
  templateUrl: './form.licitacion.component.html',
})
export class FormLicitacionComponent implements OnInit {
  public licitacion: Licitacion = new Licitacion();
  public detalle_licitacion: DetalleLicitacion[] = [];
  public detalle: DetalleLicitacion = new DetalleLicitacion();
  public empresas: Empresa[];
  public categorias: Categoria[];
  public productos: Producto[];
  public empresa: Empresa = new Empresa();
  public producto: Producto = new Producto();
  public titulo: string = "Crear Licitación";
  public errores: string[];
  public ngModel: NgModel;
  public tipo_calculo: any;
  public valor_envio: number;
  public frecuencias: FrecuenciaFedex[] = [];
  public frecuenciaSeleccionada: FrecuenciaFedex;
  private first:boolean=false;

  //Totales
  public subtotal: number;
  public iva: number;
  public total: number;
  public costo_envio: number;
  public precio_costo: number;
  public tipos_envio = [{ id: 1, descripcion: "Automático" }, { id: 2, descripcion: "Manual" }]
  public tope_utm = null;

  constructor(private empresaService: EmpresaService,
    private licitacionService: LicitacionService,
    private categoriaService: CategoriaService,
    private productoService: ProductoService,
    private modalService: NgbModal,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService,
    private decimalPipe: DecimalPipe,
    private _location: Location) { }

  ngOnInit(): void {
    this.licitacionService.getFrecuenciasFedex().subscribe(resp => {
      this.frecuencias = resp;
    })
    this.cargarLicitacion();
    this.cargarEmpresas();
    this.cargarCategorias();
    this.cargarProductos();
    this.licitacionService.getUTM().subscribe(resp => {
      this.tope_utm = Number(resp.result) * 30;
    })
  }

  getNewValue(ev: any): any {
    this.licitacion.costos_fijos = parseInt(this.decimalPipe.transform(ev));
  }

  onSubmit(ngForm) {
    this.empresa = ngForm.form.value;
    this.crearEmpresa(this.empresa);
  }

  obtenerFrecuencias(frecuencia: FrecuenciaFedex) {
    let frecuencias = frecuencia.dias_entrega.split("|");
    for (let i = 0; i < frecuencias.length; i++) {
      if (frecuencias[0] == "false" && frecuencias[1] == "false" && frecuencias[2] == "false" && frecuencias[3] == "false" && frecuencias[4] == "false") {
        return "Solo viaje especial";
      }
    }
    let fre = (frecuencias[0] == " true" || frecuencias[0] == "true" ? "Lunes | " : "") + (frecuencias[1] == "true" ? "Martes | " : "") + (frecuencias[2] == "true" ? "Miércoles | " : "") +
      (frecuencias[3] == "true" ? "Jueves | " : "") + (frecuencias[4] == "true " || frecuencias[4] == "true" ? "Viernes | " : "");
    return fre;
  }

  quitar(detalle_input: DetalleLicitacion) {
    const indice = this.detalle_licitacion.findIndex(detalle => {
      return detalle.producto_id === detalle_input.producto_id;
    })
    this.detalle_licitacion.splice(indice, 1);
    this.calcularTotales();
  }

  onSubmitP(ngForm) {
    this.producto = ngForm.form.value;
    this.crearProducto(this.producto);
  }

  agregar() {
    if (this.detalle.producto != null && this.detalle.cantidad != null && this.detalle.precio_costo != null
      && this.detalle.margen != null && this.detalle.link_producto != null && (this.tipo_calculo == 1 || (this.tipo_calculo == 2 && this.detalle.costo_envio))) {
      this.detalle.producto_id = this.detalle.producto.id;
      let bandera = false;
      this.detalle_licitacion.forEach(detalle => {
        if (detalle.producto_id == this.detalle.producto_id) {
          detalle.cantidad += this.detalle.cantidad
          detalle.margen = this.detalle.margen;
          detalle.precio_costo = this.detalle.precio_costo;
          detalle.link_producto = this.detalle.link_producto;
          bandera = true;
        }
      });
      if (!bandera) {
        this.detalle_licitacion.push(this.detalle);
      }
      this.calcularTotales();
    } else {
      Swal.fire("Información", "Debe completar todos los campos requeridos para agregar productos a licitación, favor revisar", "info");
    }
  }

  aproximar(valor): number {
    return Math.round(valor);
  }

  valorFinal(): number {
    return (((this.detalle.precio_costo * this.detalle.margen / 100) + this.detalle.precio_costo) * this.detalle.cantidad) + this.costo_envio
  }

  calcularTotales() {
    if (this.licitacion.id != null && this.first==false) {
      this.first=true;
      this.licitacion.tipo_envio = this.tipo_calculo;
      this.licitacion.costos_fijos = this.licitacion.envio_total;
    }
    this.subtotal = 0;
    this.iva = 0;
    this.total = 0;
    this.costo_envio = 0;
    this.precio_costo = 0;
    //Calculo totales y subtotales
    this.detalle_licitacion.forEach(detalle => {
      detalle.precio_venta = this.aproximar(detalle.precio_costo * detalle.margen / 100) + detalle.precio_costo
      this.subtotal += Number(detalle.precio_venta * detalle.cantidad);
    })
    //Calculo costos envios
    this.detalle_licitacion.forEach(detalle => {
      //Calculo automatico envio
      if (this.tipo_calculo == 1) {
        //Precio venta total*100/subtotalventa/100*costos fijo
        detalle.costo_envio = this.aproximar(this.aproximar(this.aproximar(detalle.precio_venta * detalle.cantidad) * 100 / this.subtotal / 100 * this.licitacion.costos_fijos) / detalle.cantidad);
        detalle.precio_venta += detalle.costo_envio;
        this.costo_envio += detalle.costo_envio * detalle.cantidad;
        //Calculo manual envio
      } else if (this.tipo_calculo == 2) {
        this.costo_envio += detalle.costo_envio * detalle.cantidad;
      }
      detalle.precio_venta = this.aproximar(detalle.precio_costo * detalle.margen / 100) + detalle.precio_costo + detalle.costo_envio;
      detalle.precio_costo_total = (detalle.precio_costo * detalle.cantidad) + detalle.costo_envio;
      detalle.precio_venta_total = (this.aproximar(detalle.precio_costo * detalle.margen / 100) + (detalle.precio_costo + detalle.costo_envio)) * detalle.cantidad;
      this.precio_costo += (detalle.precio_costo * detalle.cantidad);
    })
    this.iva = this.aproximar(Number((this.subtotal + this.costo_envio) * 19 / 100));
    this.total = Number(this.subtotal + this.iva + this.costo_envio);
    this.detalle = new DetalleLicitacion();
  }

  cargarLicitacion(): void {
    this.activedRoute.params.subscribe(params => {
      let id = params['id']
      if (id) {
        this.spinner.show();
        this.titulo = "Editar Licitación";
        this.licitacionService.getLicitacion(id).subscribe((licitacion) => {
          this.licitacion = licitacion;
          this.detalle_licitacion = licitacion.detalle_licitacions;
          this.licitacion.fecha_cierre = moment(this.licitacion.fecha_cierre).format("yyyy-MM-DD");
          this.tipo_calculo = licitacion.tipo_envio
          this.calcularTotales();
          this.spinner.hide();
        })
      }
    })
  }

  open(content) {
    this.modalService.open(content, { size: "lg" });
  }

  openP(contentP) {
    this.modalService.open(contentP, { size: "lg" });
  }

  cargarEmpresas(): void {
    this.spinner.show();
    this.empresaService.getEmpresas().subscribe((empresas) => {
      this.empresas = empresas;
      this.spinner.hide();
    })
  }

  cargarCategorias(): void {
    this.spinner.show();
    this.categoriaService.getCategorias().subscribe((categorias) => {
      this.categorias = categorias;
      this.spinner.hide();
    })
  }

  cargarProductos(): void {
    this.spinner.show();
    this.productoService.getProductos().subscribe((productos) => {
      this.productos = productos;
      this.spinner.hide();
    })
  }

  create(): void {
    if ((this.tipo_calculo == 1 || this.tipo_calculo == null) && this.licitacion.costos_fijos == null) {
      Swal.fire("Error", "Debe fijar un costo de envío", "error");
    } else {
      this.spinner.show();
      this.subtotal = 0;
      this.iva = 0;
      this.total = 0;
      this.costo_envio = 0;
      this.precio_costo = 0;
      // Se calculan totales
      this.calcularTotales();
      this.licitacion.detalle_licitacion = this.detalle_licitacion;
      // Se setean totales
      this.licitacion.sub_total_sin_envio = this.subtotal;
      this.licitacion.envio_total = this.costo_envio;
      this.licitacion.sub_total_con_envio = this.subtotal + this.costo_envio;
      this.licitacion.iva_total = this.iva;
      this.licitacion.precio_total = this.total;
      this.licitacion.precio_costo = this.precio_costo;

      this.licitacion.usuario_id = this.tokenService.getUser().id;
      if ((this.total > this.tope_utm) && this.tope_utm != null) {
        this.spinner.hide();
        Swal.fire({
          title: "La licitación excede el tope de UTM (valor tope $" + this.decimalPipe.transform(this.tope_utm, "1.0-0") + ")",
          text: "¿Quiere de igual modo realizarla?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Si',
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.crearLicitacion();
          } else if (result.isDenied) {
            Swal.fire('No se guardaron los cambios', '', 'info')
          }
        })
      } else {
        this.crearLicitacion();
      }
    }
  }

  crearLicitacion() {
    this.licitacionService.create(this.licitacion)
      .subscribe(licitacion => {
        Swal.fire("Éxito", "Licitación guardada con éxito", "success");
        this.spinner.hide();
        this.router.navigate(["licitacion"])
      },
        err => {
          this.errores = err.error.errors as string[];
          this.spinner.hide();
        });
  }

  update(): void {
    if ((this.tipo_calculo == 1 || this.tipo_calculo == null) && this.licitacion.costos_fijos == null) {
      Swal.fire("Error", "Debe fijar un costo de envio", "error");
    } else {
      this.spinner.show();
      this.subtotal = 0;
      this.iva = 0;
      this.total = 0;
      this.costo_envio = 0;
      this.precio_costo = 0;
      // Se calculan totales
      this.calcularTotales();
      this.licitacion.detalle_licitacion = this.detalle_licitacion;
      // Se setean totales
      this.licitacion.sub_total_sin_envio = this.subtotal;
      this.licitacion.envio_total = this.costo_envio;
      this.licitacion.sub_total_con_envio = this.subtotal + this.costo_envio;
      this.licitacion.iva_total = this.iva;
      this.licitacion.precio_total = this.total;
      this.licitacion.precio_costo = this.precio_costo;

      this.licitacion.ultimo_usuario_editor = this.tokenService.getUser().nombre_completo;
      this.licitacionService.update(this.licitacion)
        .subscribe(licitacion => {
          Swal.fire("Éxito", "Licitación editada con éxito", "success");
          this.spinner.hide();
          this.router.navigate(["licitacion"])
        },
          err => {
            this.errores = err.error.errors as string[];
            this.spinner.hide();
          })
    }
  }

  obtenerProducto(producto_id: number): string {
    let buscado = this.productos.find(producto => producto.id == producto_id)
    return buscado.nombre_producto;
  }

  crearEmpresa(empresa): void {
    this.spinner.show();
    this.empresaService.create(empresa)
      .subscribe(empresa => {
        Swal.fire("Éxito", "Empresa fue agregada a la base de datos con éxito", "success");
        this.spinner.hide();
        this.modalService.dismissAll();
        this.cargarEmpresas();
      },
        err => {
          this.errores = err.error.errors as string[];
          this.spinner.hide();
        });
  }

  crearProducto(producto): void {
    this.spinner.show();
    producto.categoria_id = producto.categoria;
    this.productoService.create(producto)
      .subscribe(producto => {
        Swal.fire("Éxito", "Producto fue agregado a la base de datos con éxito", "success");
        this.spinner.hide();
        this.modalService.dismissAll();
        this.cargarProductos();
      },
        err => {
          this.errores = err.error.errors as string[];
          this.spinner.hide();
        });
  }

  goBack() {
    this._location.back();
  }

  cerrarModal() {
    this.modalService.dismissAll();
  }

  compareWith(item, selected) {
    return item.id === selected.id
  }
}