import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Licitacion } from '../entidades/licitacion';
import { LicitacionService } from '../services/licitacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrdenCompra } from '../entidades/orden_compra';
import { OrdenCompraService } from '../services/orden_compra.service';
import Swal from 'sweetalert2';
import { GastoIngreso } from '../entidades/gasto_ingreso';
import { TokenStorageService } from '../services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';

@Component({
  selector: 'app-form-orden_compra',
  templateUrl: './form.orden_compra.component.html',
})
export class FormOrdenCompraComponent implements OnInit {

  public orden_compra: OrdenCompra = new OrdenCompra();
  public licitaciones: Licitacion[] = [];
  public gastos: GastoIngreso[] = [];
  public gasto: GastoIngreso = new GastoIngreso();
  public titulo: string = "Nueva orden de compra"
  public licitacionSeleccionada: Licitacion;
  public datosOC: any;
  public encontrada: boolean = true;

  //Totales
  public subtotal: number;
  public iva: number;
  public total: number;
  public costo_envio: number;

  public categorias = [{ id: 1, nombre_categoria: "ADQUISICIONES" }, { id: 2, nombre_categoria: "TRANSPORTE" }, { id: 3, nombre_categoria: "MATERIALES DE EMBALAJE" }
    , { id: 4, nombre_categoria: "SERVICIOS" }, { id: 5, nombre_categoria: "LOGÍSTICOS" }];


  constructor(private licitacionService: LicitacionService,
    private tokenService: TokenStorageService,
    private ordenCompraService: OrdenCompraService,
    private router: Router,
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _location: Location) { }

  ngOnInit(): void {
    this.cargarOrdenCompra();
  }

  cargarOrdenCompra() {
    this.activedRoute.params.subscribe(params => {
      let id = params['id']
      if (id) {
        this.spinner.show();
        this.titulo = "Editar Orden de Compra";
        this.ordenCompraService.getOrdenCompra(id).subscribe((orden_compra) => {
          this.licitacionService.getLicitaciones().subscribe(licitaciones => {
            this.ordenCompraService.getDatosOC(orden_compra.numero_orden_compra).subscribe(resp => {
              this.datosOC = resp.result;
              orden_compra.fecha_factura_entidad = moment(orden_compra.fecha_factura_entidad).format("yyyy-MM-DD")
              orden_compra.fecha_orden_compra = moment(orden_compra.fecha_orden_compra).format("yyyy-MM-DD")

              this.licitaciones = licitaciones;
              this.orden_compra = orden_compra;
              this.licitacionSeleccionada = orden_compra.licitacion;
              this.gastos = orden_compra.gasto_ingresos;
              this.encontrada = false;
              this.spinner.hide();
            })
          })
        })
      }
    })
  }

  goBack() {
    this._location.back();
  }

  buscarDatosOC() {
    this.spinner.show();
    if (this.orden_compra.numero_orden_compra != null) {
      this.ordenCompraService.getDatosOC(this.orden_compra.numero_orden_compra).subscribe(resp => {
        this.datosOC = resp.result;
        if (this.datosOC?.Listado.length == 0) {
          Swal.fire("Info", "No se encontro Orden de compra asociada al id <b>" + this.orden_compra.numero_orden_compra + "</b> en la base de datos de Mercado Público", "info");
        }
        if (resp != null) {
          let numero_licitacion = this.orden_compra.numero_orden_compra.substring(0, this.orden_compra.numero_orden_compra.length - 4);
          this.licitacionService.getLicitacionByNumeroLicitacion(numero_licitacion + "COT22").subscribe(licitacion => {
            this.encontrada = false;
            this.licitacionService.getLicitaciones().subscribe(resp => {
              this.licitaciones = resp;
              this.licitacionSeleccionada = licitacion;
            })
            this.spinner.hide();
          }, err => {
            this.encontrada = false;
            this.licitacionService.getLicitaciones().subscribe(resp => {
              this.licitaciones = resp;
            })
            this.spinner.hide();
            Swal.fire("Información", "No se encontro licitación con el id <b>" + this.orden_compra.numero_orden_compra + "</b> en la base de datos de Mercado Público " +
              " o no encontro información en nuestra base de datos", "info");
          })
        } else {
          this.spinner.hide();
          Swal.fire("No hay datos", "No se encontraron datos", "info");
        }
      })
    } else {
      this.spinner.hide();
      Swal.fire("Error", "Debe ingresar numero de orden", "error");
    }
  }

  cerrarModal() {
    this.modalService.dismissAll();
  }

  openP(contentP) {
    this.modalService.open(contentP, { size: "lg" });
  }

  agregarGasto() {
    this.gastos.push(this.gasto);
    this.gasto = new GastoIngreso();
    this.modalService.dismissAll();
  }

  guardar() {
    if (this.licitacionSeleccionada) {
      this.spinner.show();
      this.orden_compra.gastos = this.gastos;
      this.orden_compra.licitacion_id = this.licitacionSeleccionada.id;
      if (this.orden_compra.gastos.length > 0) {
        this.calcularTotales()
      }
      this.orden_compra.usuario_id = this.tokenService.getUser().id;
      this.ordenCompraService.create(this.orden_compra).subscribe(resp => {
        this.spinner.show();
        this.router.navigate(["/ordenCompra"])
        Swal.fire("Éxito", "La orden de compra ha sido guardada", "success");
      })
    } else {
      Swal.fire("Error", "Debe agregar gastos a la OC", "error");
    }
  }

  update() {
    this.calcularTotales();
    this.spinner.show();
    this.ordenCompraService.update(this.orden_compra).subscribe(resp=>{
      this.spinner.hide();
      Swal.fire("Éxito","La orden de compra fue actualizada","success");
      this.router.navigate(["/ordenCompra"]);
    })
  }

  calcularTotales() {
    let precio_venta = this.datosOC.Listado[0].Total;
    this.orden_compra.valor_costo = 0;
    this.gastos.forEach(gasto => {
      if (gasto.tipo == "GASTO") {
        this.orden_compra.valor_costo += gasto.valor;
      } else {
        this.orden_compra.valor_costo -= gasto.valor;
      }
    })
    this.orden_compra.valor_venta = Number(precio_venta);
    this.orden_compra.margen_final = Math.round((precio_venta - this.orden_compra.valor_costo) / Number(precio_venta) * 100);
    this.orden_compra.ganancia_perdida = precio_venta - this.orden_compra.valor_costo;
  }

  quitar(detalle_input: string) {
    const indice = this.gastos.findIndex(detalle => {
      return detalle[0] === detalle_input;
    })
    this.gastos.splice(indice, 1);
  }

  compareWith(item, selected) {
    return item.id === selected.id
  }
}
