<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">Usuarios</h5>
          </div>
          <div class="card-body">
            <div class="my-2 text-left">
              <button *ngIf="tokenService.hasRole('ROLE_ADMIN')" type="button" class="btn btn-rounded btn-primary"
                [routerLink]="['/usuarios/form']">Crear usuario</button>
            </div>
            <div *ngIf="usuarios?.length==0" class="alert alert-info">
              No hay registros en la base de datos
            </div>
            <div class="table-responsive">

              <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
                *ngIf="usuarios?.length>0">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Nombre</th>
                    <th>Correo corporativo</th>
                    <th>Correo personal</th>
                    <th>Cargo</th>
                    <th>Celular</th>
                    <th>Primer inicio</th>
                    <th *ngIf="tokenService.hasRole('ROLE_ADMIN')">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let usuario of usuarios">
                    <td>{{ usuario.username}}</td>
                    <td>{{ usuario.nombre_completo }}</td>
                    <td>{{ usuario.correo_corporativo}}</td>
                    <td>{{ usuario.email}}</td>
                    <td>{{ usuario.cargo}}</td>
                    <td>{{ usuario.telefono}}</td>
                    <td>{{ usuario.first}}</td>
                    <td *ngIf="tokenService.hasRole('ROLE_ADMIN')">
                      <button type="button" name="editar" class="btn btn-primary"
                        [routerLink]="['/usuarios/form', usuario.id]">Editar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
