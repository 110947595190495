import { Component, OnInit } from '@angular/core';
import { Usuario } from '../entidades/usuario';
import { UsuarioService } from '../services/usuario.service';
import { DatatablesEspaniol } from '../helper/datatables.component';
import { TokenStorageService } from '../services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent implements OnInit {

  first: boolean = true;
  usuarios: Usuario[];
  dtOptions: DataTables.Settings = {};

  constructor(private usuarioService: UsuarioService,
    private spinner:NgxSpinnerService,
    public tokenService: TokenStorageService) { }

  ngOnInit() {
    if (this.first) {
      this.spinner.show();
    }
    this.usuarioService.getUsuarios().subscribe(
      usuarios => {
        console.log(usuarios)
        this.usuarios = [];
        this.usuarios = usuarios;
        this.spinner.hide();
      });
    this.dtOptions = {
      language: DatatablesEspaniol.spanish_datatables
    };
  }
}
