import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Producto } from '../entidades/producto';


@Injectable()
export class ProductoService {

  private urlEndPoint:string = environment.apiUrl+'/api/producto';
  host :string = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


  getProductos() : Observable<Producto[]> {
    return this.http.get<Producto[]>(this.urlEndPoint).pipe(
      catchError(e => {
        return throwError(e);
      }),
    map( (response) => {
      let productos = response as Producto[];
      return productos.map(producto => {
        return producto;
      });
    }));
  }

  create(producto: Producto) : Observable<any> {
    return this.http.post(this.urlEndPoint, producto).pipe(
      map((response:any) => response as any),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  getProducto(id): Observable<Producto> {
    return this.http.get<Producto>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {
        if(e.status !=401 && e.error.mensaje){
          this.router.navigate(['/producto']);
          console.error(e.error.mensaje);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }

  update(producto: Producto) : Observable<any> {
    return this.http.put<Producto>(`${this.urlEndPoint}/${producto.id}`, producto).pipe(
      map((response:any) => response),
      catchError(e => {
        if(e.status==400){
          return throwError(e);
        }
        if (e.error.mensaje){
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    )
  }
}
