<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h5 class="m-0">Producto</h5>
          </div>
          <div class="card-body">
            <div class="my-2 text-left">
              <button *ngIf="tokenService.hasRole('ROLE_MODERATOR')" type="button" class="btn btn-rounded btn-primary"
                [routerLink]="['/producto/form']"><i class="fa fa-plus"></i> Crear producto</button>
            </div>
            <div *ngIf="productos?.length==0" class="alert alert-info">
              No hay registros en la base de datos
            </div>
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" class="table table-bordered table-striped"
                *ngIf="productos?.length>0">
                <thead>
                  <tr>
                    <th>Nombre producto</th>
                    <th *ngIf="tokenService.hasRole('ROLE_MODERATOR')">Editar</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let producto of productos">
                    <td>{{producto.nombre_producto}}</td>
                    <td *ngIf="tokenService.hasRole('ROLE_MODERATOR')">
                      <button type="button" name="editar" class="btn btn-primary"
                        [routerLink]="['/producto/form', producto.id]"><i class="fas fa-edit"></i> Editar</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
