import { Direccion } from "../direccion";
import { OrdenCompra } from "../orden_compra";

export class Envio {
    direccion:Direccion;
    direccion_envio:Direccion;

    orden:OrdenCompra;
    despacho:string;
    nombre_unidad:string;
    nombre_contacto:string;
    telefono_contacto:string;
    seguimiento:string;
    numero_bultos:number;
    fecha_envio:Date;
}