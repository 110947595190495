<div class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h5 class="m-0">{{titulo}}</h5>
                    </div>
                    <div class="card-body">
                        <form #ordenCompraForm="ngForm">
                            <div class="form-group row">
                                <label for="nombres" class="col-form-label col-sm-2">Número orden de compra</label>
                                <div class="col-sm-2">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="orden_compra.numero_orden_compra" name="numero_orden_compra"
                                        required minlength="4" #numero_orden_compra="ngModel">
                                    <div class="alert alert-danger"
                                        *ngIf="numero_orden_compra.invalid && (numero_orden_compra.dirty || numero_orden_compra.touched)">
                                        <div *ngIf="numero_orden_compra.errors.required">
                                            Número orden de compra es obligatorio
                                        </div>
                                        <div *ngIf="numero_orden_compra.errors.minlength">
                                            Número orden de compra debe contener al menos 4 caracteres
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-success" [disabled]="orden_compra.id" (click)="buscarDatosOC()"><i class="fa fa-search"></i>
                                    Buscar datos</button>
                                <label for="apellidos" class="col-form-label col-sm-1">Fecha orden de compra</label>
                                <div class="col-sm-2">
                                    <input type="date" class="form-control"
                                        [(ngModel)]="orden_compra.fecha_orden_compra" [readonly]="orden_compra.id"  name="fecha_orden_compra" required
                                        #fecha_orden_compra="ngModel">
                                    <div class="alert alert-danger"
                                        *ngIf="fecha_orden_compra.invalid && (fecha_orden_compra.dirty || fecha_orden_compra.touched)">
                                        <div *ngIf="fecha_orden_compra.errors.required">
                                            Fecha orden de compra es obligatorio
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="!encontrada">
                                <label for="nombres" class="col-form-label col-sm-2">Seleccione licitación</label>
                                <div class="col-sm-6">
                                    <ng-select [readonly]="orden_compra.id" name="licitacion" [compareWith]="compareWith"
                                        placeholder="Seleccione licitación correspondiente a OC"
                                        [(ngModel)]="licitacionSeleccionada">
                                        <ng-option *ngFor="let licitacion of licitaciones" [value]="licitacion">
                                            {{licitacion.numero_licitacion}} - {{licitacion.empresa.nombre_empresa}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="licitacionSeleccionada">
                                <label for="apellidos" class="col-form-label col-sm-2">Número factura (nuestra)</label>
                                <div class="col-sm-2">
                                    <input type="text" class="form-control"
                                        [(ngModel)]="orden_compra.numero_factura_entidad" name="numero_factura_entidad"
                                         #numero_factura_entidad="ngModel">
                                </div>
                                <label for="apellidos" class="col-form-label col-sm-1">Fecha factura (nuestra)</label>
                                <div class="col-sm-3">
                                    <input type="date" class="form-control"
                                        [(ngModel)]="orden_compra.fecha_factura_entidad" name="fecha_factura_entidad"
                                         #fecha_factura_entidad="ngModel">
                                </div>
                            </div>
                            <div class="accordion" id="accordionExample">
                                <div class="card" *ngIf="datosOC?.Listado?.length>0">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="btn btn-link btn-block text-left" type="button"
                                                data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                Información orden de compra obtenida desde Mercado Público
                                            </button>
                                        </h2>
                                    </div>

                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                        data-parent="#accordionExample">
                                        <div class="card-body alert alert-info">
                                            <p>
                                                <b>Nombre organismo:
                                                </b>{{datosOC.Listado[0].Comprador.NombreOrganismo}}<br>
                                                <b>Nombre unidad: </b>{{datosOC.Listado[0].Comprador.NombreUnidad}}<br>
                                                <b>RUT Unidad: </b>{{datosOC.Listado[0].Comprador.RutUnidad}}<br>
                                                <b>Dirección Unidad:
                                                </b>{{datosOC.Listado[0].Comprador.DireccionUnidad}}<br>
                                                <b>Comuna Unidad: </b>{{datosOC.Listado[0].Comprador.ComunaUnidad}}<br>
                                                <b>Región Unidad: </b>{{datosOC.Listado[0].Comprador.RegionUnidad}}<br>

                                                <b>Nombre contacto:
                                                </b>{{datosOC.Listado[0].Comprador.NombreContacto}}<br>
                                                <b>Cargo contacto:
                                                </b>{{datosOC.Listado[0].Comprador.CargoContacto}}<br>
                                                <br>
                                            </p>
                                            <p>
                                                <b>Descripción: </b>{{datosOC.Listado[0].Descripcion}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-danger"
                                *ngIf="licitacionSeleccionada?.observaciones_licitacion=='CARGA MASIVA'">
                                La licitación seleccionada fue cargada con carga masiva (no se cargo detalle)
                            </div>
                        </form>

                        <div class="accordion" id="accordionExample">
                            <div class="card" *ngIf="licitacionSeleccionada?.detalle_licitacions?.length>0">
                                <div class="card-header" id="headingOne">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left" type="button"
                                            data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                                            aria-controls="collapseTwo">
                                            Detalle orden obtenida de nuestra base de datos
                                        </button>
                                    </h2>
                                </div>

                                <div id="collapseTwo" class="collapse show" aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Precio neto unitario</th>
                                                    <th scope="col">Precio neto total</th>
                                                    <th scope="col">Link Producto</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let detalle of licitacionSeleccionada?.detalle_licitacions">
                                                    <td>{{detalle.producto.nombre_producto}}</td>
                                                    <td>{{detalle.cantidad}}</td>
                                                    <td>{{detalle.precio_venta | number: '2.0-0'}}</td>
                                                    <td>{{detalle.precio_venta*detalle.cantidad | number: '2.0-0'}}</td>
                                                    <td><a href="{{detalle.link_producto}}" target="_blank">Ir</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card" *ngIf="datosOC?.Listado?.length>0">
                                <div class="card-header" id="headingTwo">
                                    <h2 class="mb-0">
                                        <button class="btn btn-link btn-block text-left collapsed" type="button"
                                            data-toggle="collapse" data-target="#collapseTree" aria-expanded="false"
                                            aria-controls="collapseTree">
                                            Detalle orden obtenida desde Mercado Público
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTree" class="collapse" aria-labelledby="headingTwo"
                                    data-parent="#accordionExample">
                                    <div class="card-body">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Producto</th>
                                                    <th scope="col">Cantidad</th>
                                                    <th scope="col">Precio neto unitario</th>
                                                    <th scope="col">Precio neto total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let detalle of datosOC?.Listado[0].Items.Listado">
                                                    <td>{{detalle.EspecificacionComprador}}</td>
                                                    <td>{{detalle.Cantidad}}</td>
                                                    <td>{{detalle.PrecioNeto | number: '2.0-0'}}</td>
                                                    <td>{{detalle.Total | number: '2.0-0'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning">
                            <p><b>Para imputar los gastos/costos, debe ingresar al modulo contabilidad y seleccionar la orden de compra respectiva</b></p>
                            <!-- <p><b>Imputar nuevo costo/gasto a orden de compra</b></p> -->
                            <!-- <button class="btn btn-success" (click)="openP(contentP)"><i class="fa fa-plus"></i>
                                Agregar</button> -->
                        </div>
                        <div *ngIf="gastos.length>0">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Detalle</th>
                                        <th scope="col">Valor</th>
                                        <th scope="col">Proveedor</th>
                                        <th scope="col">Categoría</th>
                                        <th scope="col">Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let gasto of gastos">
                                        <td>{{gasto.descripcion}}</td>
                                        <td>${{gasto.valor | number: '2.0-0'}}</td>
                                        <td>{{gasto.proveedor}}</td>
                                        <td>{{gasto.categoria}}</td>
                                        <td>{{gasto.fecha | date:'dd/MM/yyyy':"UTC"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="alert alert-info" *ngIf="licitacionSeleccionada || datosOC?.Listado.length>0"
                            style="width: 50%;">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th *ngIf="licitacionSeleccionada">Datos BD nuestra</th>
                                        <th *ngIf="datosOC?.Listado?.length>0">Datos Mercado Público</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Sub-Total</th>
                                        <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.sub_total_con_envio
                                            | number: '2.0-0'}}</td>
                                        <td *ngIf="datosOC?.Listado?.length>0">${{datosOC.Listado[0].TotalNeto | number:
                                            '2.0-0'}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">IVA 19%</th>
                                        <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.iva_total | number:
                                            '2.0-0'}}</td>
                                        <td *ngIf="datosOC?.Listado?.length>0">${{datosOC.Listado[0].Impuestos | number:
                                            '2.0-0'}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td *ngIf="licitacionSeleccionada">${{licitacionSeleccionada.precio_total |
                                            number: '2.0-0'}}</td>
                                        <td *ngIf="datosOC?.Listado.length>0">${{datosOC.Listado[0].Total | number:
                                            '2.0-0'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn btn-primary" role="button" (click)='guardar()'
                        *ngIf="!orden_compra.id; else elseBlock" [disabled]="!ordenCompraForm.form.valid"
                        style="margin-right: 5px;"> <i class="fas fa-save"></i> Guardar OC</button>
                      <ng-template #elseBlock>
                        <button class="btn btn-primary" role="button" (click)='update()'
                          [disabled]="!ordenCompraForm.form.valid" style="margin-right: 5px;"><i class="fas fa-edit"></i> Editar</button>
                      </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentP let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Agregar nuevo ingreso/costo/gasto</h4>
    </div>
    <div class="modal-body">
        <form #gastoForm="ngForm">
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Descripción gasto</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="gasto.descripcion" name="descripcion_gasto"
                        minlength="4" #descripcion_gasto="ngModel" required>
                    <div class="alert alert-danger"
                        *ngIf="descripcion_gasto.invalid && (descripcion_gasto.dirty || descripcion_gasto.touched)">
                        <div *ngIf="descripcion_gasto.errors.minlength">
                            Descripción gasto debe contener al menos 4 caracteres
                        </div>
                        <div *ngIf="descripcion_gasto.errors.required">
                            Descripción gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Valor gasto</label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="gasto.valor" name="valor_gasto" minlength="4"
                        #valor_gasto="ngModel">
                    <div class="alert alert-danger"
                        *ngIf="valor_gasto.invalid && (valor_gasto.dirty || valor_gasto.touched)">
                        <div *ngIf="valor_gasto.errors.required">
                            Valor gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Fecha gasto</label>
                <div class="col-sm-6">
                    <input type="date" class="form-control" [(ngModel)]="gasto.fecha" name="fecha_gasto" minlength="4"
                        #fecha_gasto="ngModel">
                    <div class="alert alert-danger"
                        *ngIf="fecha_gasto.invalid && (fecha_gasto.dirty || fecha_gasto.touched)">
                        <div *ngIf="valor_gasto.errors.required">
                            Fecha gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Proveedor gasto</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" [(ngModel)]="gasto.proveedor" name="proveedor_gasto"
                        minlength="4" #proveedor_gasto="ngModel" required>
                    <div class="alert alert-danger"
                        *ngIf="proveedor_gasto.invalid && (proveedor_gasto.dirty || proveedor_gasto.touched)">
                        <div *ngIf="proveedor_gasto.errors.minlength">
                            Proveedor gasto debe contener al menos 4 caracteres
                        </div>
                        <div *ngIf="proveedor_gasto.errors.required">
                            Proveedor gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Tipo documento recibido</label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="gasto.tipo_documento" placeholder="Seleccione tipo de documento recibido"
                        required name="tipo_documento" #tipo_documento="ngModel">
                        <ng-option value="FACTURA">FACTURA</ng-option>
                        <ng-option value="FACTURA">BOLETA</ng-option>
                    </ng-select>
                    <div class="alert alert-danger"
                        *ngIf="tipo_documento.invalid && (tipo_documento.dirty || tipo_documento.touched)">
                        <div *ngIf="tipo_documento.errors.required">
                            Tipo documento recibido es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Número documento recibido</label>
                <div class="col-sm-6">
                    <input type="number" class="form-control" [(ngModel)]="gasto.numero_documento"
                        name="numero_documento" minlength="4" #numero_documento="ngModel">
                    <div class="alert alert-danger"
                        *ngIf="numero_documento.invalid && (numero_documento.dirty || numero_documento.touched)">
                        <div *ngIf="numero_documento.errors.required">
                            Número documento es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Categoría gasto</label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="gasto.categoria"
                        placeholder="Seleccione categoría a la cual pertenece el gasto" required name="categoria_gasto"
                        #categoria_gasto="ngModel">
                        <ng-option *ngFor="let categoria of categorias" [value]="categoria.nombre_categoria">
                            {{categoria.nombre_categoria}}</ng-option>
                    </ng-select>
                    <div class="alert alert-danger"
                        *ngIf="categoria_gasto.invalid && (categoria_gasto.dirty || categoria_gasto.touched)">
                        <div *ngIf="categoria_gasto.errors.required">
                            Categoría gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="categoria?.tiene_sub">
                <label for="apellidos" class="col-form-label col-sm-2">Sub categoria gasto</label>
                <div class="col-sm-6">
                    <ng-select [(ngModel)]="gasto.categoria"
                        placeholder="Seleccione sub-categoría a la cual pertenece el gasto" required
                        name="sub_categoria_gasto" #sub_categoria_gasto="ngModel">
                        <ng-option *ngFor="let sub_categoria of sub_categorias" [value]="sub_categoria.id">
                            {{sub_categoria.nombre_sub_categoria}}</ng-option>
                    </ng-select>
                    <div class="alert alert-danger"
                        *ngIf="sub_categoria_gasto.invalid && (sub_categoria_gasto.dirty || sub_categoria_gasto.touched)">
                        <div *ngIf="sub_categoria_gasto.errors.required">
                            Sub Categoría gasto es obligatorio
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="apellidos" class="col-form-label col-sm-2">Observaciones gasto (opcional)</label>
                <div class="col-sm-6">
                    <textarea type="number" class="form-control" [(ngModel)]="gasto.observacion"
                        name="observacion_gasto" minlength="4" #observacion_gasto="ngModel"></textarea>
                </div>
            </div>
            <button class="btn btn-primary" role="button" type="submit" *ngIf="!gasto.id; else elseBlock"
                [disabled]="!gastoForm.form.valid" style="margin-right: 5px;" (click)="agregarGasto()">Crear</button>
            <button type="button" class="btn btn-danger" (click)="cerrarModal()">Volver</button>
        </form>
    </div>
</ng-template>