<ul class="alert alert-danger" *ngIf="errores?.length > 0">
  <li *ngFor="let err of errores">
    {{ err }}
  </li>
</ul>

<div [ngClass]="{'container':this.tokenService.getUser().first}" class="card bg-dark text-white">
  <div class="card-header">{{titulo}}</div>
  <div class="card-body">
    <form #usuarioForm="ngForm">
      <div class="form-group row">
        <label for="nombres" class="col-form-label col-sm-2">Contraseña actual</label>
        <div class="col-sm-6">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control" [(ngModel)]="change.currentPassword" name="currentPassword" required minlength="4" #currentPassword="ngModel">
          <div class="alert alert-danger" *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched)">
            <div *ngIf="currentPassword.errors.required">
              Contraseña actual es obligatoria
            </div>
            <div *ngIf="currentPassword.errors.minlength">
              Contraseña actual debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <input type="checkbox" (change)="showHidePassword($event)" > Mostrar constraseña actual

      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Contraseña nueva</label>
        <div class="col-sm-6">
          <input [type]="showPasswordNew ? 'text' : 'password'" class="form-control" [(ngModel)]="change.newPassword" name="apellidos" required minlength="4" #apellidos="ngModel">
          <div class="alert alert-danger" *ngIf="apellidos.invalid && (apellidos.dirty || apellidos.touched)">
            <div *ngIf="apellidos.errors.required">
              Contraseña nueva es obligatoria
            </div>
            <div *ngIf="apellidos.errors.minlength">
              Contraseña nueva debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>
      <input type="checkbox" (change)="mostrarClavesNuevas($event)" > Mostrar constraseña nueva y confirmación

      
      <div class="form-group row">
        <label for="apellidos" class="col-form-label col-sm-2">Confirme contraseña nueva</label>
        <div class="col-sm-6">
          <input [type]="showPasswordCon ? 'text' : 'password'" class="form-control" [(ngModel)]="change.confirmPassword" name="apellidos" required minlength="4" #apellidos="ngModel">
          <div class="alert alert-danger" *ngIf="apellidos.invalid && (apellidos.dirty || apellidos.touched)">
            <div *ngIf="apellidos.errors.required">
              Contraseña confimarción es obligatoria
            </div>
            <div *ngIf="apellidos.errors.minlength">
              Contraseña confimarción debe contener al menos 4 caracteres
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <button class="btn btn-primary" role="button" (click)='cambiarPassword()' [disabled]="!usuarioForm.form.valid" >Cambiar contraseña</button>
          <button *ngIf="!tokenService.getUser().first" class="btn btn-danger" mat-raised-button (click)="goBack()" style="margin-left: 10px;">Volver</button>
          <button *ngIf="tokenService.getUser().first" class="btn btn-danger" mat-raised-button (click)="tokenService.signOut()" style="margin-left: 10px;">Cerrar sesión</button>
        </div>
      </div>
    </form>
  </div>
</div>
